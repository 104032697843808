import { memo, useMemo, useContext, useCallback } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { useLocation, useHistory } from "react-router-dom"
import { getLocFromRef, getCorrespondingRefs } from "@bibletags/bibletags-versification"
import { getUsfmBibleBookAbbr } from '@bibletags/bibletags-ui-helper'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import LaunchIcon from '@material-ui/icons/Launch'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ShareIcon from '@material-ui/icons/Share'
import CheckIcon from '@material-ui/icons/Check'
import { getRefFromLoc } from '@bibletags/bibletags-versification'

import {
  GET_STUDY_BIBLE_ITEM_TYPES, KJV_VERSION,
} from '../../../utils/constants'
import { getOrigVersionInfo, getSecondsFromTimeString, getTimeStringFromSeconds } from '../../../utils/misc'
import useDataQuery from '../../../hooks/useDataQuery'
// import useStudyBibleItemPageShortcuts from '../../../hooks/useStudyBibleItemPageShortcuts'
import useVersesPieces from '../../../hooks/useVersesPieces'
import usePassagePopper from '../../../hooks/usePassagePopper'
import useAppSize from '../../../hooks/useAppSize'
import useVersionInfos from '../../../hooks/useVersionInfos'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useShareOrCopy from '../../../hooks/useShareOrCopy'
import usePassageRef from '../../../hooks/usePassageRef'
import { LoggedInUserContext } from '../../../context/LoggedInUser'
import useSetStudyBibleItemInfo from '../../../hooks/useSetStudyBibleItemInfo'
import useSimpleToggle from '../../../hooks/useSimpleToggle'

import StudyBibleItemInsightContent from '../../study-bible/StudyBibleItemInsightContent'
import StudyBibleItemSermonContent from '../../study-bible/StudyBibleItemSermonContent'
import StudyBibleItemImageContent from '../../study-bible/StudyBibleItemImageContent'
import StudyBibleItemMapContent from '../../study-bible/StudyBibleItemMapContent'
import StudyBibleItemGenealogyContent from '../../study-bible/StudyBibleItemGenealogyContent'
import StudyBibleItemTimelineContent from '../../study-bible/StudyBibleItemTimelineContent'
import StudyBibleItemIntroductionContent from '../../study-bible/StudyBibleItemIntroductionContent'
import Header from '../../common/Header'
import StudyBibleItemPageNotFound from "./StudyBibleItemPageNotFound"
import Loading from "../../common/Loading"
import StudyBibleItemSource from '../../study-bible/StudyBibleItemSource'
import StudyBibleItemType from '../../study-bible/StudyBibleItemType'
import StudyBibleItemTags from '../../study-bible/StudyBibleItemTags'
import TextContent from '../../common/TextContent'
import LinkIconButton from '../../common/LinkIconButton'
import PassageRef from '../../common/PassageRef'
import LinkButton from '../../common/LinkButton'
import StudyBibleItemComments from '../../study-bible/StudyBibleItemComments'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'
import CustomSwitch from '../../common/CustomSwitch'

import studyBibleItemQuery from '../../../graphql/queries/studyBibleItem'

const MEDIA_COMPONENT_WIDTH = 600
const REACTION_TYPES = [ `HEART`, `THUMBS-UP`, `THUMBS-DOWN`, `QUESTION-MARK` ]

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px 40px;
  background-color: white;
`

const InnerContainer = styled.div`
  position: relative;
`

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: calc(100vh - 250px);
`

const HeadingPlus = styled.div`
  font-size: 13px;
  text-align: center;
  margin: 30px 0;
`

const Heading = styled.div`
  font-style: italic;
  font-size: 18px;
`

const Type = styled.span`
  margin: 0 5px;
`

const PassageRefHeading = styled.div`
  position: absolute;
  top: -9px;
  left: 18px;
  padding: 0 6px;
  align-self: flex-start;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  background: white;
  color: ${({ theme }) => theme.palette.grey[700]};
`

const PassageRefForMobile = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin: 2px 0 5px;
  transition: opacity .15s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  .MuiSvgIcon-root {
    font-size: 12px;
  }

  @media (hover: hover) {
    &:hover {
      opacity: .5;
    }
  }
`

const Version = styled.span`
  display: inline-block;
  font-weight: 300;
  margin-left: 4px;
  font-size: .75em;
`

const Passage = styled.div`
  margin: 20px 0 10px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 20px 24px;
  border-radius: 5px;
  position: relative;
  min-width: 250px;
`

const ContentComponentContainer = styled.div`
  width: ${MEDIA_COMPONENT_WIDTH}px;
  max-width: calc(100vw - 20px);
  text-align: center;
  position: relative;
  overflow: hidden;

  .StudyBibleItemInsightContent-FreehandContainer {
    width: ${({ $width }) => $width}px;
    margin: 0 auto;
  }

  .StudyBibleItemInsightContent-ImageContainer {
    width: ${({ $width }) => $width}px;
  }

  .VerticalInsetShadow {
    margin: 0;
  }

  ${({ $recordingMode }) => !$recordingMode ? `` : `
    .StudyBibleItemInsightContent-FreehandOuterContainer {
      margin: 70px 0 50px;
    }
    .FreehandContainer-ReplayIconButton {
      transform: translateY(-70px);
    }
    .VerticalInsetShadow {
      box-shadow: none;
    }
  `}

`

const StyledStudyBibleItemSource = styled(StudyBibleItemSource)`
  margin: 40px 0 10px;
  font-size: 13px;
  max-width: 600px;
  padding: 0;
`

const EditFab = styled(Fab)`
  position: fixed;
  bottom: 15px;
  right: 15px;
`

const LaunchIconButton = styled(LinkIconButton)`
  padding: 8px;
  margin: -5px -6px -5px 0;

  .MuiSvgIcon-root {
    font-size: 11px;
  }
`

const TagsAndType = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const ClipLength = styled.div`
  font-size: 11px;
  font-weight: 400;
`

const StyledLinkButton = styled(LinkButton)`
  align-self: flex-start;
  margin: 5px -5px 20px;

  @media (max-width: 870px) {
    margin: 5px -5px;
  }
`

const ShareCopyButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 40px 0;
`

const StyledStudyBibleItemComments = styled(StudyBibleItemComments)`
  width: 600px;
  max-width: calc(100vw - 12px);
`

const StyledCustomSwitch = styled(CustomSwitch)`
  margin: 10px 0 -20px;
`

const CommentsHeading = styled.div`
  font-weight: 500;
  margin: 25px 0 15px;
  font-size: 18px;
`

const StudyBibleItemPage = ({
  // goPrintOrDownload,
  ...otherProps
}) => {

  const location = useLocation()
  const id = decodeURIComponent(location.pathname.split('/')[2])
  const showInternalComments = /#internal-comments(?:#|$)/.test(location.hash)
  const history = useHistory()

  const { unhiddenSelectedVersionInfos } = useVersionInfos()
  const { version, safeVersionAbbr, id: versionId } = unhiddenSelectedVersionInfos[0] || {}  // i.e. the defaultVersionId

  const { adminLevel } = useContext(LoggedInUserContext) || {}

  const [ recordingMode, toggleRecordingMode ] = useSimpleToggle()

  const { studyBibleItem, loading, refetch } = useDataQuery({
    studyBibleItemQuery,
    variables: {
      id,
    },
  })

  const {
    heading,
    title,
    type=`INSIGHT`,
    info,
    fromLoc,
    toLoc,
    details,
    // reactions,
    tags,
    // createdAt,
  } = studyBibleItem || {}

  const {
    quote,
    sourceText,
  } = info || {}

  const defaultInputForNew = useMemo(
    () => ({
      type: `PUBLIC`,
      studyBibleItemId: id,
    }),
    [ id ],
  )

  // useStudyBibleItemPageShortcuts({
  //   goPrintOrDownload,
  // })

  const passageStr = usePassageRef({
    fromLoc,
    toLoc,
    convertToVersionId: versionId,
  })

  const { copyOrShare, justCopiedOrShared, copyOptionsComponent } = useShareOrCopy({
    shareData: {
      title,
      text: `${heading} | ${passageStr}`,
      url: `${document.location.origin}/study-bible/${id}`,
    },
    giveShareOnXOption: true,
  })

  let contentToCopy = `${title}\n${heading} | ${passageStr}`
  if(quote) {
    contentToCopy += `\n\n“${quote}”`
  }
  if((details || []).length > 0) {
    contentToCopy += `\n\n${details.map(({ text }) => text).join(`\n\n`)}`
  }
  if(sourceText) {
    contentToCopy += `\n\n${i18n("Source: {{source}}", { source: sourceText })}`
  }
  contentToCopy += `\n\n${document.location.origin}/study-bible/${id}`
  const { goCopy, justCopiedOrShared: justCopied } = useShareOrCopy({
    shareData: {
      url: contentToCopy,
    },
  })

  const setStudyBibleItemInfo = useSetStudyBibleItemInfo()
  const goEdit = useCallback(
    () => {
      setStudyBibleItemInfo({
        studyBibleItem,
        versionId,
        goStraightToEditor: true,
      })
    },
    [ setStudyBibleItemInfo, studyBibleItem, versionId ],
  )

  const { versionFromLoc, versionToLoc } = useMemo(
    () => {
      if(!fromLoc) return {}

      const getConvertedLoc = ({ loc, directionToTryIfSkipped=`previous`, atIdx=0 }) => (
        getLocFromRef(
          getCorrespondingRefs({
            baseVersion: {
              ref: getRefFromLoc(loc),
              info: getOrigVersionInfo(),
            },
            lookupVersionInfo: version || KJV_VERSION,
            directionToTryIfSkipped,
          }).at(atIdx)
        ).split(':')[0]
      )

      return {
        versionFromLoc: getConvertedLoc({ loc: fromLoc }),
        versionToLoc: getConvertedLoc({ loc: toLoc, directionToTryIfSkipped: `next`, atIdx: -1 }),
      }
    },
    [ version, fromLoc, toLoc ]
  )

  const { bookId, chapter } = getRefFromLoc(versionFromLoc || fromLoc || `01001001`)
  const searchParams = new URLSearchParams(``)
  searchParams.set(`range`, `${versionFromLoc}-${versionToLoc}`)
  const readLink = versionId && versionFromLoc && `/version/${versionId}/${getUsfmBibleBookAbbr(bookId)}/${chapter}?${searchParams.toString()}`

  const [ pieces ] = useVersesPieces({
    fromLoc: versionFromLoc,
    toLoc: versionToLoc,
    versionId,
    skip: !fromLoc || !versionId,
  })

  const { width, studyBibleItemPopoverSize } = useAppSize()

  const { goSetPopperInfo, passagePopperComponents } = usePassagePopper({
    width,
    versionId,
  })

  const studyBibleItemReady = (studyBibleItem || {}).id === id
  useEffectAsync(
    () => {
      if(showInternalComments && studyBibleItemReady) {
        goEdit()
        setTimeout(() => {
          history.replace({
            hash: document.location.hash.replace(/#internal-comments(#|$)?/, '$1'),
          })
        })
      }
    },
    [ showInternalComments, studyBibleItemReady ],
  )

  if(!loading && !studyBibleItem) {
    return (
      <StudyBibleItemPageNotFound
        {...otherProps}
      />
    )
  }

  const { imageDimensions={}, mediaStartTime, mediaEndTime } = info || {}
  const durationInSeconds = Math.max(getSecondsFromTimeString(mediaEndTime) - getSecondsFromTimeString(mediaStartTime), 0)

  const ContentComponent = {
    INSIGHT: StudyBibleItemInsightContent,
    QUOTE: StudyBibleItemInsightContent,
    SERMON: StudyBibleItemSermonContent,
    LECTURE: StudyBibleItemSermonContent,
    PODCAST: StudyBibleItemSermonContent,
    COURSE: StudyBibleItemSermonContent,
    IMAGE: StudyBibleItemImageContent,
    MAP: StudyBibleItemMapContent,
    GENEALOGY: StudyBibleItemGenealogyContent,
    TIMELINE: StudyBibleItemTimelineContent,
    CREED: StudyBibleItemInsightContent,
    INTRODUCTION: StudyBibleItemIntroductionContent,
  }[type]

  return (
    <>

      <Header
        showStudyBibleLogo
        title={title}
        {...otherProps}
      />

      <Container key={id}>
        <InnerContainer>

          {loading && <Loading />}

          <StyledLinkButton
            to="/"  // back to the Bible
            startIcon={<ArrowBackIcon />}
            color="primary"
          >
            {i18n("Back to the Bible")}
          </StyledLinkButton>

          {!loading &&
            <Content>

              {studyBibleItemPopoverSize &&
                <Passage>

                  <PassageRefHeading>

                    <PassageRef
                      fromLoc={versionFromLoc}
                      toLoc={versionToLoc}
                    />

                    {` `}

                    <Version>
                      {safeVersionAbbr}
                    </Version>

                    <LaunchIconButton
                      to={readLink}
                    >
                      <LaunchIcon />
                    </LaunchIconButton>

                  </PassageRefHeading>

                  <TextContent
                    pieces={pieces}
                    versionId={versionId}
                    bookId={bookId}
                    startChapter={chapter}
                    goSetPopperInfo={goSetPopperInfo}
                  />

                </Passage>
              }

              <HeadingPlus>

                <Heading>
                  {heading}
                </Heading>

                {!studyBibleItemPopoverSize &&
                  <NavLinkOrAWithDisable
                    to={readLink}
                  >
                    <PassageRefForMobile $type={type}>
                      <PassageRef
                        fromLoc={versionFromLoc}
                        toLoc={versionToLoc}
                      />
                      <LaunchIcon />
                    </PassageRefForMobile>
                  </NavLinkOrAWithDisable>
                }

                <TagsAndType>

                  <StudyBibleItemTags
                    tags={tags}
                    type={type}
                  />

                  <Type>
                    <StudyBibleItemType $type={type}>
                      {GET_STUDY_BIBLE_ITEM_TYPES()[type].label}
                    </StudyBibleItemType>
                  </Type>

                  {[ `SERMON`, `LECTURE`, `PODCAST`, `COURSE` ].includes(type) &&
                    <ClipLength>
                      {getTimeStringFromSeconds(durationInSeconds)}
                    </ClipLength>
                  }

                </TagsAndType>

              </HeadingPlus>


              <ContentComponentContainer
                $width={([ `SERMON`, `LECTURE`, `PODCAST`, `COURSE` ].includes(type) ? MEDIA_COMPONENT_WIDTH : imageDimensions.width)}
                $recordingMode={recordingMode}
              >
                <ContentComponent
                  type={type}
                  info={info}
                  details={details}
                  manualMode={recordingMode}
                />
              </ContentComponentContainer>

              {[ 'ADMIN', 'EDITOR', 'MARKETING' ].includes(adminLevel) &&
                <StyledCustomSwitch
                  checked={recordingMode}
                  onChange={toggleRecordingMode}
                  label={i18n("Recording mode")}
                />
              }

              <StyledStudyBibleItemSource
                type={type}
                info={info}
              />

              <ShareCopyButtons>

                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  onClick={copyOrShare}
                  startIcon={justCopiedOrShared ? <CheckIcon /> : <ShareIcon />}
                >
                  {i18n("Share")}
                </Button>

                {copyOptionsComponent}

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={goCopy}
                  startIcon={justCopied ? <CheckIcon /> : <FileCopyIcon />}
                >
                  {i18n("Copy Content")}
                </Button>

              </ShareCopyButtons>


              <CommentsHeading>
                {i18n("Discussion")}
              </CommentsHeading>

              <StyledStudyBibleItemComments
                defaultInputForNew={defaultInputForNew}
                query={`studyBibleItemId:${id} type:PUBLIC`}
                reactionTypes={REACTION_TYPES}
                refetchItem={refetch}
              />

              {/* from The ___ Study Bible (with logo) */}
              {/* "also by" section? */}

            </Content>
          }

          {passagePopperComponents}

        </InnerContainer>
      </Container>

      {[ 'ADMIN', 'EDITOR', 'CONTRIBUTOR' ].includes(adminLevel) &&
        <>

          <EditFab
            color="secondary"
            onClick={goEdit}
          >
            <EditIcon />
          </EditFab>

        </>
      }

    </>
  )
}

export default memo(StudyBibleItemPage)