import { useMemo } from 'react'

import { cloneObj } from '../utils/misc'
import { GET_STUDY_BIBLE_ITEM_TYPES, STUDY_BIBLE_CHANNELS } from '../utils/constants'
import useDataQuery from './useDataQuery'

import streamingServiceChannelQuery from '../graphql/queries/streamingServiceChannel'
import channelQuery from '../graphql/queries/channel'

const emptyObj = {}

const useChannelInfo = ({
  channelId,
  channelUrl,
  type,
 }={}) => {

  const popularStudyBibleChannel = STUDY_BIBLE_CHANNELS.find(channel => channel.channelUrl === channelUrl)

  const { streamingServiceChannel, loading: streamingServiceChannelLoading } = useDataQuery({
    streamingServiceChannelQuery,
    variables: {
      id: channelUrl,
    },
    dataOnError: null,
    skip: !channelUrl || !!popularStudyBibleChannel,
    sticky: true,
  })

  const { channel, loading: channelLoading } = useDataQuery({
    channelQuery,
    variables: {
      id: channelId,
    },
    dataOnError: null,
    skip: !channelId,
    sticky: true,
  })

  const loading = streamingServiceChannelLoading || channelLoading

  const channelToUse = cloneObj(
    popularStudyBibleChannel
    || streamingServiceChannel
    || channel
    || {}
  )

  let {
    imageURL,  // for broadcasters
    roundedThumbnailImageURL,  // for speakers
    updatedAt,
    iconLogoSrc,
  } = channelToUse

  const nonChannelInfo = (type !== `CHANNEL` && GET_STUDY_BIBLE_ITEM_TYPES()[type]) || emptyObj

  const channelImg = (
    nonChannelInfo.imgSrc
    || imageURL
    || iconLogoSrc
    || roundedThumbnailImageURL
    || (
      channelId
      && `${process.env.REACT_APP_ASSETS_URI}${channelId}-favicon_192-${updatedAt}.png`
    )
    // darkLogoSrc
  )

  const toReturn = useMemo(
    () => ({
      ...nonChannelInfo,
      ...channelToUse,
      channelImg,
      loading,
    }),
    [ channelImg, channelToUse, nonChannelInfo, loading ],
  )

  return toReturn

}

export default useChannelInfo