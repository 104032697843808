import { memo, useContext, useRef } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Snackbar from '@material-ui/core/Snackbar'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useLocation } from "react-router-dom"

import { TransitionUp } from '../common/CustomSnackbar'

import useEffectAsync from '../../hooks/useEffectAsync'
import useVersionInfos from '../../hooks/useVersionInfos'
import useAccountSetting from '../../hooks/useAccountSetting'
import useStickyRefState from '../../hooks/useStickyRefState'
import useSetTimeout from '../../hooks/useSetTimeout'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import { ChannelItemInfoContext } from '../../context/ChannelItemInfo'
import { ChannelIdInPWAContext } from '../../context/ChannelIdInPWA'
import { getLocalStorage, removeLocalStorage } from '../../utils/misc'
import { LoggedInUserContext } from '../../context/LoggedInUser'

import ChannelItem from '../study-bible/ChannelItem'
import MyPlanPromoSpot from '../common/MyPlanPromoSpot'

const StyledSnackbar = styled(Snackbar)`
  left: 50%;
  right: auto;
  transform: translateX(-50%);

  @media (max-width: 599px) {
    bottom: 10px;
  }

  @media (max-width: 384px) {
    left: 10px;
    right: 10px;
    transform: none;
  }

  .MuiSnackbarContent-root {
    padding: 0;
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0;
  }
  .MuiSnackbarContent-message {
    padding: 0;
    transition: box-shadow .15s ease-in-out;
    &:focus-within {
      box-shadow: 0px 3px 12px -1px rgba(0,0,0,0.5),0px 6px 20px 0px rgba(0,0,0,0.35),0px 1px 36px 0px rgba(0,0,0,0.32);
    }
  }
`

const TooltipContent = styled.div`
  .MyPlanPromoSpot-AboutSection {
    margin-top: -20px;
  }
`

const TooltipContentTop = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
`

const CloseIconButton = styled(IconButton)`
  padding: 8px;
  margin: 2px -2px 2px 4px;
  .MuiSvgIcon-root {
    font-size: 16px;
  }
`

const DidYouKnow = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding: 10px 0 10px 10px;
  font-style: italic;
  line-height: 1.2;
`

const StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  .MuiTooltip-arrow::before,
  .MuiTooltip-tooltip {
    background: ${({ theme }) => theme.palette.primary.faded};
  }
  .MuiTooltip-tooltip {
    color: black;
  }
`

const ChannelItemSnackbar = () => {

  const { hasMyPlan } = useContext(LoggedInUserContext) || {}
  const [ hasToldOfSketching, setHasToldOfSketching ] = useStickyRefState({ id: `ChannelItemSnackbar:hasToldOfSketching`, defaultValue: false })
  const [ tellOfSketching, toggleTellOfSketching ] = useSimpleToggle(false)
  const location = useLocation()
  const [ setTellOfSketchingTimeout, clearTellOfSketchingTimeout ] = useSetTimeout()

  const { channelItemInfo, setChannelItemInfo } = useContext(ChannelItemInfoContext)

  const {
    anchorEl,
    studyBibleItem,  // warning: this will not be up-to-date if edited by StudyBibleItemEditor; but that should be okay, since from the editor the user can only close
    versionId,
    autoplay,
    onClose,
    ...otherProps
  } = channelItemInfo

  const [ listeningHistory, x1, x2, loading ] = useAccountSetting(`listening-history`, [])  // eslint-disable-line no-unused-vars
  const onInitialLoad = useRef(true)
  const { defaultVersionId } = useVersionInfos()
  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)

  const isLAB = /^https:\/\/www\.desiringgod\.org/.test((studyBibleItem || {}).url || ``)

  useEffectAsync(
    () => {
      if(!loading && onInitialLoad.current) {
        onInitialLoad.current = false

        const { open, id, title, channelId } = listeningHistory[0] || {}

        if(
          open
          && (
            channelIdInPWA
            || !getLocalStorage(`message-snackbar-open`)
          )
        ) {
          const studyBibleFollow = {
            id: `none`,
            channelId,
          }
          setChannelItemInfo({
            studyBibleItem: {
              id,
              title,
              ...(channelId ? { studyBibleFollow } : {}),
              type: `CHANNEL`
            },
            versionId: defaultVersionId,
            anchorEl: true,
            autoplay: false,
          })
        } else {
          removeLocalStorage(`message-snackbar-open`)
        }
      }
    },
    [ loading ],
  )

  useEffectAsync(
    () => {
      clearTellOfSketchingTimeout()
      if(tellOfSketching) {
        toggleTellOfSketching({ force: false })
      }
    },
    [ location.pathname, !!anchorEl ],
  )

  useEffectAsync(
    () => {
      if(
        !hasToldOfSketching
        && !hasMyPlan
        && isLAB
      ) {
        setTellOfSketchingTimeout(() => {
          toggleTellOfSketching({ force: true })
          setHasToldOfSketching(true)
        }, 500)
      }
    },
    [ !!anchorEl ],
  )

  return (
    <StyledTooltip
      arrow
      open={!!anchorEl && tellOfSketching}
      interactive
      title={
        <TooltipContent>
          <TooltipContentTop>
            <DidYouKnow>
              {i18n(`Did you know you can create your own Look at the Book-style sketches?`)}
            </DidYouKnow>
            <CloseIconButton
              onClick={toggleTellOfSketching}
            >
              <CloseIcon />
            </CloseIconButton>
          </TooltipContentTop>
          <MyPlanPromoSpot
            showSketchPreview
            sketchPreviewWidth={274}
            subscribeToMessage={i18n("Subscribe to add sketches.")}
          />
        </TooltipContent>
      }
    >
      <StyledSnackbar
        className="ChannelItemSnackbar"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        TransitionComponent={TransitionUp}
        open={!!anchorEl}
        elevation={24}
        transitionDuration={300}
        message={
          <ChannelItem
            studyBibleItem={studyBibleItem}
            onClose={onClose}
            versionId={versionId}
            autoplay={autoplay}
          />
        }
        {...otherProps}
      />
    </StyledTooltip>
  )

}

export default memo(ChannelItemSnackbar)