const keyDownSpace = async params => {

  const {
    event,
    toggleInEditingMode,
  } = params

  if(!toggleInEditingMode) return

  event.preventDefault()

  toggleInEditingMode()

}

export default keyDownSpace