import useStudyBibleCuratedItems from './useStudyBibleCuratedItems'
import useStudyBibleChannelItems from './useStudyBibleChannelItems'
import useEqualObjsMemo from './useEqualObjsMemo'
import { dedup } from '../utils/misc'
import useDelay from './useDelay'

const useStudyBibleItems = ({ chapterInfo, channelIdOrUrl }) => {

  const skip = !!channelIdOrUrl
  const [ chapterLoc1, chapterLoc2, chapterLoc3 ] = chapterInfo.originalChapterLocs || []

  let loading = false

  const studyBibleItems = useEqualObjsMemo(
    dedup(
      (
        [
          useStudyBibleCuratedItems({ chapterLoc: chapterLoc1, skip }),
          useStudyBibleCuratedItems({ chapterLoc: chapterLoc2, skip }),
          useStudyBibleCuratedItems({ chapterLoc: chapterLoc3, skip }),
          useStudyBibleChannelItems({ chapterLoc: chapterLoc1, channelIdOrUrl }),
          useStudyBibleChannelItems({ chapterLoc: chapterLoc2, channelIdOrUrl }),
          useStudyBibleChannelItems({ chapterLoc: chapterLoc3, channelIdOrUrl }),
        ]
          .map(set => {
            loading = loading || set.loading
            return set.studyBibleItems || set.studyBibleChannelItems || []
          })
          .flat()
      ),
      ({ id }) => id,
    )
      .filter(chapterInfo.filterForVersion)
      .sort((a,b) => (
        (
          a.fromLoc < b.fromLoc
          || (
            a.fromLoc === b.fromLoc
            && a.toLoc >= b.toLoc  // this puts wider range items first (e.g. 1:1-20 will come before 1:1)
          )
        ) ? -1 : 1
      ))
  )

  const studyBibleItemsAfterThrottle = useDelay(studyBibleItems, 200)

  return {
    studyBibleItems: studyBibleItemsAfterThrottle,
    loading,
  }
}

export default useStudyBibleItems
