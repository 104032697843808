import { useMemo, useState } from 'react'

import useEffectAsync from './useEffectAsync'
import useIsLoggedIn from './useIsLoggedIn'
import useVersionInfo from './useVersionInfo'
import useDataQuery from './useDataQuery'
import useInstanceValuesCallback from './useInstanceValuesCallback'

import taggingEditorInfoByLanguageIdQuery from '../graphql/queries/taggingEditorInfoByLanguageId'

const confirmedAlready = {}
const emptyObj = []

const useTaggingEditorInfo = ({
  loc,
  setLoc,
  setVersionId,
  versionId,
}) => {

  const isLoggedIn = useIsLoggedIn()
  const { version: { languageId }={} } = useVersionInfo(versionId)
  const [ nextToConfirm, setNextToConfirm ] = useState()

  const { taggingEditorInfoByLanguageId } = useDataQuery({
    taggingEditorInfoByLanguageIdQuery,
    pollInterval: 1000*60*15,  // 15 minutes
    skip: !isLoggedIn,
    dataOnError: null,
  })

  const { otLocsNeedingConfirm, ntLocsNeedingConfirm } = (taggingEditorInfoByLanguageId || {})[languageId] || {}
  const locsNeedingConfirm = (/^[0-3]/.test(loc) ? otLocsNeedingConfirm : ntLocsNeedingConfirm) || emptyObj

  const taggersReport = useMemo(
    () => {
      let completeTaggersReport = ``
      Object.values(taggingEditorInfoByLanguageId || {}).forEach(({ taggersReport }) => {
        if(taggersReport) {
          completeTaggersReport += `${completeTaggersReport ? taggersReport.replace(/^.*?\n/, `\n`) : taggersReport}`
        }
      })
      return completeTaggersReport
    },
    [ taggingEditorInfoByLanguageId ],
  )

  const updateNextToConfirm = useInstanceValuesCallback(
    () => {
      setNextToConfirm(
        locsNeedingConfirm.find(({ versionId, loc }) => (
          !confirmedAlready[`${versionId}:${loc}`]
        ))
      )
    },
  )

  const goToNextToConfirm = useInstanceValuesCallback(
    () => {
      if(nextToConfirm) {
        const { versionId, loc } = nextToConfirm
        setLoc(loc)
        setVersionId(versionId)
      }
    },
  )

  const markConfirmed = useInstanceValuesCallback(
    () => {
      confirmedAlready[`${versionId}:${loc}`] = true
      updateNextToConfirm()
    },
  )

  useEffectAsync(updateNextToConfirm, [ locsNeedingConfirm ])

  return {
    taggersReport,
    goToNextToConfirm: nextToConfirm ? goToNextToConfirm : null,
    markConfirmed,
  }

}

export default useTaggingEditorInfo