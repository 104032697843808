import { memo, useCallback } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { getRefFromLoc } from '@bibletags/bibletags-versification'

import { getBoundedValue, isIOSOrAndroid, preventDefaultEvent } from '../../utils/misc'
import { SKETCH_FONT_SIZE, SKETCH_LINE_HEIGHT } from './MakeASketchMenuItem'
import { SKETCH_TEXT_HORIZONTAL_PADDING, SKETCH_TEXT_VERTICAL_PADDING } from '../../utils/constants'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import useAppSize from '../../hooks/useAppSize'
import useVersesPieces from '../../hooks/useVersesPieces'

import NavLinkOrAWithDisable from '../common/NavLinkOrAWithDisable'
import PassageRef from '../common/PassageRef'
import TextContent from '../common/TextContent'
import SketchCanvas from '../common/SketchCanvas'

const WIDTH = 208
const SKETCH_TEXT_HORIZONTAL_PADDING_FOR_THUMBNAIL = 15
const SKETCH_TEXT_VERTICAL_PADDING_FOR_THUMBNAIL = 10

const Container1 = styled.div`
  position: relative;
`

const Slot = styled.div`
  position: absolute;
  right: -10px;
  top: -15px;
  bottom: -15px;
  width: 1px;
  background-color: ${({ theme }) => theme.palette.grey[300]};
  z-index: 2;

  ${({ $isRTL }) => !$isRTL ? `` : `
    right: auto;
    left: -20px;
  `}

`

const Container2 = styled.div`
  margin-top: ${({ $mini }) => $mini ? 0 : 7}px;
  margin-bottom: 10px;
  transition: transform .25s ease-in-out;
  z-index: 1;

  ${({ $mini, $miniRevealed, $isRTL }) => (!$mini || ($miniRevealed === $isRTL)) ? `` : `
    transform: translateX(-190px);
  `}
`

const MiniPassageRefContainer2 = styled.div`
  transition: opacity .25s ease-in-out;
  opacity: ${({ $hide }) => $hide ? 0 : 1};
  pointer-events: ${({ $hide }) => $hide ? `none` : `all`};
  font-size: 13px;
  white-space: nowrap;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  position: absolute;
  top: 0;
  left: 8px;
  background-color: ${({ theme }) => theme.palette.grey[800]};
  color: white;
  padding: 2px 6px;
  border-radius: 0 0 3px 3px;
`

const Content = styled.div`
  width: ${WIDTH}px;
  max-height: ${({ $mini, $fromViewOrMoreLink, $height }) => ($mini ? 103 : ($fromViewOrMoreLink ? 138 : $height))}px;
  min-height: ${({ $mini, $fromViewOrMoreLink, $height }) => ($mini ? 103 : ($fromViewOrMoreLink ? 138 : $height))}px;
  overflow: hidden;
  transition: box-shadow .25s ease-in-out;
  user-select: none;
  box-shadow: ${({ $mini }) => $mini ? `inset 0 0 12px rgb(0 0 0/.3)` : `inset 0 0 8px rgb(0 0 0/.15)`};
  position: relative;
  background-color: white;

  @media (hover: hover) {
    &:hover {
      box-shadow: inset 0 0 120px rgb(0 0 0/.15);
      cursor: pointer;
    }
  }
`

const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 4;
  padding: 10px;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $hide }) => $hide ? 0 : 1};
  pointer-events: ${({ $hide }) => $hide ? `none` : `all`};
  background-color: rgb(255 255 255/.65);
  backdrop-filter: blur(3px);

  @media (hover: hover) {
    &:hover {
      background-color: white;
    }
  }

  .MuiSvgIcon-root {
    font-size: 16px;
  }
`

const MiniPassageRefContainer = styled.div`
  position: absolute;
  top: 0;
  left: -103px;
  width: 103px;
  line-height: 1;
  padding: 8px;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transform: rotate(270deg);
  transform-origin: top right;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $visible }) => $visible ? 1 : 0};


  ${({ $isRTL }) => !$isRTL ? `` : `
    justify-content: flex-start;
    transform: rotate(90deg);
    transform-origin: top left;
    left: auto;
    right: -103px;
  `}
`

const PassageRefBackground = styled.span`
  display: inline-block;
  background-color: ${({ theme }) => theme.palette.grey[800]};
  color: white;
  padding: 2px 3px 1px;
  margin: -2px;
  border-radius: 2px;
`

const TextContentContainer = styled.div`

  line-height: ${SKETCH_LINE_HEIGHT}px;
  font-size: ${SKETCH_FONT_SIZE}px;
  padding: ${SKETCH_TEXT_VERTICAL_PADDING}px ${SKETCH_TEXT_HORIZONTAL_PADDING}px;

  white-space: nowrap;
  direction: ${({ $isRTL }) => $isRTL ? 'rtl' : 'ltr'};

  ${({ $breaks }) => (
    ($breaks || [])
      .map(emptySpanNums => `
        & > .TextContent-empty-span-${emptySpanNums} {
          display: block;
        }
      `)
      .join(`\n`)
  )}
`

const BackgroundContainer = styled.div`
  width: ${({ $lineWidth }) => ($lineWidth || 380) + SKETCH_TEXT_HORIZONTAL_PADDING*2}px;
  transform: scale(${({ $scale }) => $scale})
             translateX(${SKETCH_TEXT_HORIZONTAL_PADDING_FOR_THUMBNAIL - SKETCH_TEXT_HORIZONTAL_PADDING}px)
             translateY(${SKETCH_TEXT_VERTICAL_PADDING_FOR_THUMBNAIL - SKETCH_TEXT_VERTICAL_PADDING}px);
  transform-origin: top left;
`

const StudyBibleItemSketchThumbnail = ({
  mini,
  isRTL,
  studyBibleItem,
  setItemInfo,
  onClose,
  fromViewOrMoreLink,
}) => {

  const { highlight } = studyBibleItem
  const {
    // __typename,
    id,
    // note,
    // shared,
    // userId,
    // user,  // TODO
    info,
    // createdAt,
    savedAt,
    versionId,
    fromLoc,
    toLoc,
    fromWordNumber,
    toWordNumber,
  } = highlight

  const { textSettings, lineWidth, svgStrokes, breaks } = info
  const { bookId, chapter: startChapter, verse: startVerse } = getRefFromLoc(fromLoc)

  const [ pieces ] = useVersesPieces({
    fromLoc: `${fromLoc}:${fromWordNumber}`,
    toLoc: `${toLoc}:${toWordNumber}`,
    versionId,
  })

  // const { miniHeaderSize, studyBibleItemPopoverSize } = useAppSize()
  const { miniHeaderSize } = useAppSize()
  const [ miniRevealed, toggleMiniRevealed ] = useSimpleToggle()

  const linkDisabled = true // studyBibleItemPopoverSize

  const closeMiniRevealed = useCallback(
    event => {
      preventDefaultEvent(event)
      toggleMiniRevealed({ force: false })
    },
    [ toggleMiniRevealed ],
  )

  const open = useCallback(
    event => {
      if(mini && !miniRevealed) {
        preventDefaultEvent(event)
        toggleMiniRevealed()
      } else if(linkDisabled) {
        let anchorEl = event.currentTarget
        if(isIOSOrAndroid) {
          anchorEl = document.body.querySelector(`.Header-Container`) || document.body
        } else if(mini || fromViewOrMoreLink) {
          anchorEl = document.body
        }
        setItemInfo({
          highlight,
          versionId,
          anchorEl,
          onClose,
        })
        if(miniRevealed) toggleMiniRevealed()
      }
    },
    [ setItemInfo, highlight, versionId, mini, miniRevealed, toggleMiniRevealed, linkDisabled, onClose, fromViewOrMoreLink ],
  )

  const scale = WIDTH / ((lineWidth || 380) + SKETCH_TEXT_HORIZONTAL_PADDING_FOR_THUMBNAIL*2)

  return (
    <NavLinkOrAWithDisable
      to={`/study-bible-note/${encodeURIComponent(id)}`}
      disabled={linkDisabled}
    >
      <Container1>

        <Container2
          onClick={open}
          $miniRevealed={miniRevealed}
          $isRTL={isRTL}
          $mini={mini}
        >

          <Content
            onClick={mini ? toggleMiniRevealed : null}
            $mini={mini}
            $fromViewOrMoreLink={fromViewOrMoreLink}
            $height={getBoundedValue((((breaks || []).length+1) * SKETCH_LINE_HEIGHT + SKETCH_TEXT_VERTICAL_PADDING_FOR_THUMBNAIL*2) * scale, { min: 40, max: 180 })}
          >

            <BackgroundContainer
              $lineWidth={lineWidth}
              $scale={scale}
            >

              <TextContentContainer
                $breaks={breaks}
                $isRTL={isRTL}
              >
                <TextContent
                  pieces={pieces}
                  versionId={versionId}
                  bookId={bookId}
                  startChapter={startChapter}
                  startVerse={startVerse}
                  {...(textSettings || {})}
                  wrapNonSpaceTextInSpan
                />
              </TextContentContainer>

              <SketchCanvas
                key={savedAt}
                svgStrokes={svgStrokes}
              />

            </BackgroundContainer>

            {mini &&
              <MiniPassageRefContainer2
                $hide={!miniRevealed}
              >
                <PassageRef
                  fromLoc={fromLoc}
                  toLoc={toLoc}
                  versionId={versionId}
                />
              </MiniPassageRefContainer2>
            }

            {mini &&
              <MiniPassageRefContainer
                $visible={mini && !miniRevealed}
                $isRTL={isRTL}
              >
                <PassageRefBackground>
                  <PassageRef
                    fromLoc={fromLoc}
                    toLoc={toLoc}
                    versionId={versionId}
                    withoutBookName
                  />
                </PassageRefBackground>
              </MiniPassageRefContainer>
            }

            {mini &&
              <CloseIconButton
                onClick={closeMiniRevealed}
                $hide={!miniRevealed}
              >
                <CloseIcon />
              </CloseIconButton>
            }

          </Content>

        </Container2>

        {mini && !miniHeaderSize && <Slot $isRTL={isRTL} />}

      </Container1>
    </NavLinkOrAWithDisable>
  )
}

export default memo(StudyBibleItemSketchThumbnail)