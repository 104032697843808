import { memo, useState } from 'react'
import styled from 'styled-components'
import { getRefFromLoc } from '@bibletags/bibletags-versification'

import { SKETCH_FONT_SIZE, SKETCH_LINE_HEIGHT } from '../passage/MakeASketchMenuItem'
import { SKETCH_TEXT_VERTICAL_PADDING, SKETCH_TEXT_HORIZONTAL_PADDING } from '../../utils/constants'
import { getBoundedValue } from '../../utils/misc'
import useVersesPieces from '../../hooks/useVersesPieces'
import useSetTimeout from '../../hooks/useSetTimeout'
import useEffectAsync from '../../hooks/useEffectAsync'

import TextContent from '../common/TextContent'
import SketchCanvas from '../common/SketchCanvas'
import useAppSize from '../../hooks/useAppSize'

const DEMO_HIGHLIGHT = {
  versionId: `esv`,
  fromLoc: `43001001`,
  toLoc: `43001002`,
  fromWordNumber: 1,
  toWordNumber: 7,
  info: {
    "type": "SKETCH",
    "isNew": false,
    "breaks": [
      "14",
      "29",
      "45"
    ],
    "lineWidth": 290,
    "svgStrokes": [
      {
        "points": [
          [
            85,
            95
          ],
          [
            97,
            74
          ],
          [
            82,
            68
          ],
          [
            48,
            74
          ],
          [
            19,
            95
          ],
          [
            18,
            116
          ],
          [
            50,
            120
          ],
          [
            89,
            109
          ],
          [
            107,
            95
          ],
          [
            103,
            81
          ],
          [
            85,
            78
          ],
          [
            51,
            80
          ]
        ],
        "options": {
          "size": 12,
          "color": "RED",
          "utensil": "MARKER"
        },
        "strokeOptionsObj": {
          "size": 12,
          "thinning": 0.65,
          "smoothing": 0.5,
          "streamline": 0.5
        }
      },
      {
        "points": [
          [
            69,
            84
          ],
          [
            69,
            83
          ],
          [
            69,
            81
          ],
          [
            68,
            81
          ],
          [
            62,
            84
          ],
          [
            51,
            88
          ],
          [
            42,
            93
          ],
          [
            36,
            99
          ],
          [
            37,
            103
          ],
          [
            45,
            104
          ],
          [
            55,
            105
          ],
          [
            69,
            103
          ],
          [
            82,
            98
          ],
          [
            87,
            95
          ],
          [
            90,
            90
          ],
          [
            89,
            88
          ],
          [
            86,
            85
          ],
          [
            80,
            81
          ],
          [
            72,
            79
          ],
          [
            61,
            80
          ],
          [
            50,
            87
          ],
          [
            41,
            93
          ],
          [
            36,
            99
          ],
          [
            35,
            106
          ],
          [
            36,
            109
          ],
          [
            41,
            110
          ],
          [
            50,
            110
          ],
          [
            64,
            105
          ],
          [
            80,
            97
          ],
          [
            86,
            93
          ],
          [
            87,
            86
          ],
          [
            85,
            84
          ],
          [
            79,
            84
          ],
          [
            67,
            85
          ],
          [
            54,
            90
          ],
          [
            47,
            96
          ],
          [
            45,
            100
          ],
          [
            49,
            100
          ],
          [
            59,
            96
          ],
          [
            73,
            92
          ],
          [
            79,
            89
          ],
          [
            77,
            89
          ],
          [
            72,
            94
          ],
          [
            71,
            96
          ],
          [
            72,
            94
          ]
        ],
        "options": {
          "size": 10,
          "color": "PINK",
          "utensil": "HIGHLIGHTER"
        },
        "strokeOptionsObj": {
          "end": {
            "cap": false
          },
          "size": 10,
          "start": {
            "cap": false
          },
          "opacity": 0.5,
          "thinning": 0,
          "smoothing": 0.5,
          "streamline": 0.5
        }
      },
      {
        "points": [
          [
            61,
            73
          ],
          [
            61,
            73
          ],
          [
            61,
            73
          ],
          [
            62,
            73
          ],
          [
            63,
            73
          ],
          [
            64,
            70
          ],
          [
            72,
            60
          ],
          [
            91,
            50
          ],
          [
            104,
            50
          ],
          [
            118,
            54
          ],
          [
            127,
            62
          ]
        ],
        "options": {
          "size": 8,
          "color": "RED",
          "utensil": "MARKER"
        },
        "strokeOptionsObj": {
          "size": 8,
          "thinning": 0.65,
          "smoothing": 0.5,
          "streamline": 0.5
        }
      },
      {
        "points": [
          [
            114,
            48
          ],
          [
            113,
            47
          ],
          [
            113,
            46
          ],
          [
            116,
            46
          ],
          [
            120,
            51
          ],
          [
            125,
            58
          ],
          [
            127,
            60
          ],
          [
            127,
            60
          ],
          [
            126,
            60
          ],
          [
            123,
            60
          ],
          [
            117,
            60
          ],
          [
            109,
            60
          ]
        ],
        "options": {
          "size": 8,
          "color": "RED",
          "utensil": "MARKER"
        },
        "strokeOptionsObj": {
          "size": 8,
          "thinning": 0.65,
          "smoothing": 0.5,
          "streamline": 0.5
        }
      },
      {
        "points": [
          [
            225,
            91
          ],
          [
            226,
            89
          ],
          [
            227,
            87
          ],
          [
            223,
            82
          ],
          [
            203,
            76
          ],
          [
            172,
            83
          ],
          [
            157,
            103
          ],
          [
            167,
            121
          ],
          [
            198,
            128
          ],
          [
            227,
            122
          ],
          [
            233,
            104
          ],
          [
            221,
            87
          ],
          [
            181,
            75
          ]
        ],
        "options": {
          "size": 12,
          "color": "GREEN",
          "utensil": "MARKER"
        },
        "strokeOptionsObj": {
          "size": 12,
          "thinning": 0.65,
          "smoothing": 0.5,
          "streamline": 0.5
        }
      },
      {
        "points": [
          [
            211,
            92
          ],
          [
            211,
            92
          ],
          [
            211,
            92
          ],
          [
            209,
            91
          ],
          [
            206,
            89
          ],
          [
            199,
            86
          ],
          [
            188,
            87
          ],
          [
            179,
            92
          ],
          [
            174,
            98
          ],
          [
            175,
            105
          ],
          [
            178,
            112
          ],
          [
            182,
            115
          ],
          [
            189,
            116
          ],
          [
            197,
            117
          ],
          [
            206,
            117
          ],
          [
            213,
            116
          ],
          [
            217,
            112
          ],
          [
            217,
            107
          ],
          [
            217,
            102
          ],
          [
            215,
            97
          ],
          [
            211,
            94
          ],
          [
            207,
            92
          ],
          [
            202,
            89
          ],
          [
            198,
            88
          ],
          [
            191,
            90
          ],
          [
            184,
            93
          ],
          [
            177,
            97
          ],
          [
            175,
            101
          ],
          [
            177,
            109
          ],
          [
            182,
            112
          ],
          [
            188,
            112
          ],
          [
            199,
            109
          ],
          [
            208,
            105
          ],
          [
            210,
            102
          ],
          [
            208,
            100
          ],
          [
            204,
            100
          ],
          [
            198,
            101
          ],
          [
            192,
            105
          ],
          [
            190,
            109
          ],
          [
            193,
            110
          ],
          [
            198,
            109
          ],
          [
            201,
            108
          ],
          [
            205,
            102
          ],
          [
            202,
            95
          ],
          [
            196,
            94
          ],
          [
            192,
            96
          ],
          [
            188,
            100
          ],
          [
            188,
            102
          ],
          [
            191,
            103
          ],
          [
            197,
            102
          ],
          [
            200,
            100
          ],
          [
            199,
            100
          ],
          [
            197,
            99
          ],
          [
            195,
            99
          ]
        ],
        "options": {
          "size": 10,
          "color": "GREEN",
          "utensil": "HIGHLIGHTER"
        },
        "strokeOptionsObj": {
          "end": {
            "cap": false
          },
          "size": 10,
          "start": {
            "cap": false
          },
          "opacity": 0.5,
          "thinning": 0,
          "smoothing": 0.5,
          "streamline": 0.5
        }
      },
      {
        "points": [
          [
            202,
            85
          ],
          [
            201,
            83
          ],
          [
            200,
            81
          ],
          [
            204,
            78
          ],
          [
            216,
            71
          ],
          [
            240,
            63
          ],
          [
            259,
            63
          ],
          [
            267,
            68
          ],
          [
            273,
            81
          ],
          [
            274,
            91
          ]
        ],
        "options": {
          "size": 8,
          "color": "GREEN",
          "utensil": "MARKER"
        },
        "strokeOptionsObj": {
          "size": 8,
          "thinning": 0.65,
          "smoothing": 0.5,
          "streamline": 0.5
        }
      },
      {
        "points": [
          [
            277,
            115
          ],
          [
            277,
            114
          ],
          [
            277,
            113
          ],
          [
            279,
            118
          ],
          [
            283,
            122
          ],
          [
            292,
            127
          ],
          [
            303,
            129
          ],
          [
            310,
            127
          ],
          [
            313,
            121
          ],
          [
            313,
            114
          ],
          [
            311,
            109
          ]
        ],
        "options": {
          "size": 8,
          "color": "GREEN",
          "utensil": "MARKER"
        },
        "strokeOptionsObj": {
          "size": 8,
          "thinning": 0.65,
          "smoothing": 0.5,
          "streamline": 0.5
        }
      },
      {
        "points": [
          [
            302,
            116
          ],
          [
            302,
            117
          ],
          [
            303,
            117
          ],
          [
            309,
            116
          ],
          [
            313,
            111
          ],
          [
            315,
            109
          ],
          [
            317,
            111
          ],
          [
            319,
            114
          ],
          [
            323,
            122
          ]
        ],
        "options": {
          "size": 8,
          "color": "GREEN",
          "utensil": "MARKER"
        },
        "strokeOptionsObj": {
          "size": 8,
          "thinning": 0.65,
          "smoothing": 0.5,
          "streamline": 0.5
        }
      },
      {
        "points": [
          [
            178,
            130
          ],
          [
            165,
            104
          ],
          [
            146,
            101
          ],
          [
            119,
            108
          ],
          [
            102,
            124
          ],
          [
            109,
            146
          ],
          [
            132,
            154
          ],
          [
            164,
            148
          ],
          [
            186,
            136
          ],
          [
            183,
            128
          ],
          [
            161,
            118
          ]
        ],
        "options": {
          "size": 12,
          "color": "ORANGE",
          "utensil": "MARKER"
        },
        "strokeOptionsObj": {
          "size": 12,
          "thinning": 0.65,
          "smoothing": 0.5,
          "streamline": 0.5
        }
      },
      {
        "points": [
          [
            158,
            124
          ],
          [
            155,
            123
          ],
          [
            156,
            121
          ],
          [
            155,
            119
          ],
          [
            150,
            115
          ],
          [
            140,
            117
          ],
          [
            131,
            122
          ],
          [
            122,
            128
          ],
          [
            120,
            135
          ],
          [
            126,
            143
          ],
          [
            136,
            149
          ],
          [
            151,
            146
          ],
          [
            163,
            140
          ],
          [
            171,
            132
          ],
          [
            170,
            125
          ],
          [
            167,
            117
          ],
          [
            163,
            113
          ],
          [
            154,
            112
          ],
          [
            141,
            113
          ],
          [
            131,
            115
          ],
          [
            119,
            118
          ],
          [
            114,
            121
          ],
          [
            112,
            128
          ],
          [
            113,
            135
          ],
          [
            118,
            141
          ],
          [
            128,
            145
          ],
          [
            141,
            147
          ],
          [
            153,
            143
          ],
          [
            163,
            137
          ],
          [
            164,
            128
          ],
          [
            160,
            124
          ],
          [
            153,
            124
          ],
          [
            145,
            123
          ],
          [
            133,
            125
          ],
          [
            126,
            131
          ],
          [
            130,
            137
          ],
          [
            136,
            138
          ],
          [
            146,
            136
          ],
          [
            152,
            130
          ],
          [
            153,
            127
          ],
          [
            146,
            131
          ],
          [
            139,
            137
          ],
          [
            138,
            137
          ],
          [
            145,
            131
          ],
          [
            149,
            128
          ],
          [
            150,
            129
          ],
          [
            149,
            132
          ]
        ],
        "options": {
          "size": 10,
          "color": "ORANGE",
          "utensil": "HIGHLIGHTER"
        },
        "strokeOptionsObj": {
          "end": {
            "cap": false
          },
          "size": 10,
          "start": {
            "cap": false
          },
          "opacity": 0.5,
          "thinning": 0,
          "smoothing": 0.5,
          "streamline": 0.5
        }
      },
      {
        "points": [
          [
            163,
            148
          ],
          [
            163,
            148
          ],
          [
            163,
            149
          ],
          [
            169,
            160
          ],
          [
            181,
            176
          ],
          [
            196,
            179
          ],
          [
            215,
            171
          ],
          [
            223,
            159
          ],
          [
            225,
            147
          ],
          [
            226,
            142
          ]
        ],
        "options": {
          "size": 8,
          "color": "ORANGE",
          "utensil": "MARKER"
        },
        "strokeOptionsObj": {
          "size": 8,
          "thinning": 0.65,
          "smoothing": 0.5,
          "streamline": 0.5
        }
      },
      {
        "points": [
          [
            208,
            149
          ],
          [
            208,
            149
          ],
          [
            209,
            151
          ],
          [
            217,
            152
          ],
          [
            227,
            146
          ],
          [
            229,
            141
          ],
          [
            229,
            142
          ],
          [
            229,
            145
          ],
          [
            230,
            149
          ],
          [
            236,
            156
          ]
        ],
        "options": {
          "size": 8,
          "color": "ORANGE",
          "utensil": "MARKER"
        },
        "strokeOptionsObj": {
          "size": 8,
          "thinning": 0.65,
          "smoothing": 0.5,
          "streamline": 0.5
        }
      },
      {
        "points": [
          [
            258,
            144
          ],
          [
            259,
            143
          ],
          [
            265,
            141
          ],
          [
            281,
            142
          ],
          [
            300,
            144
          ],
          [
            321,
            146
          ],
          [
            335,
            146
          ],
          [
            338,
            146
          ]
        ],
        "options": {
          "size": 8,
          "color": "black",
          "utensil": "MARKER"
        },
        "strokeOptionsObj": {
          "size": 8,
          "thinning": 0.65,
          "smoothing": 0.5,
          "streamline": 0.5
        }
      },
      {
        "points": [
          [
            38,
            175
          ],
          [
            37,
            175
          ],
          [
            31,
            175
          ],
          [
            29,
            176
          ],
          [
            35,
            177
          ],
          [
            56,
            175
          ],
          [
            90,
            172
          ],
          [
            127,
            172
          ],
          [
            154,
            173
          ],
          [
            189,
            175
          ],
          [
            216,
            175
          ],
          [
            232,
            176
          ],
          [
            239,
            176
          ],
          [
            239,
            173
          ]
        ],
        "options": {
          "size": 8,
          "color": "black",
          "utensil": "MARKER"
        },
        "strokeOptionsObj": {
          "size": 8,
          "thinning": 0.65,
          "smoothing": 0.5,
          "streamline": 0.5
        }
      }
    ],
    "textSettings": {
      "showCfs": true,
      "showNotes": true,
      "greekPunctuation": "ENGLISH",
      "showCantillation": true,
      "showGreekAccents": true,
      "showHebrewVowels": true
    },
    "utensilState": {
      "selected": "MARKER",
      "markerSize": 8,
      "pencilSize": 2,
      "eraserColor": "RED",
      "markerColor": "BLUE",
      "pencilColor": "BLUE",
      "highlighterSize": 10,
      "highlighterColor": "BLUE"
    }
  },
}

const SKETCH_TEXT_HORIZONTAL_PADDING_FOR_DEMO = 15
const SKETCH_TEXT_VERTICAL_PADDING_FOR_DEMO = 10

const Container = styled.div`
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  position: relative;
  user-select: none;
  overflow: hidden;
  border: .5px solid rgb(0 0 0/.25);
  background: white;
  margin: -1px;
`

const TextContentContainer = styled.div`

  line-height: ${SKETCH_LINE_HEIGHT}px;
  font-size: ${SKETCH_FONT_SIZE}px;
  text-align: left;
  padding: ${SKETCH_TEXT_VERTICAL_PADDING}px ${SKETCH_TEXT_HORIZONTAL_PADDING}px;

  white-space: nowrap;
  direction: ${({ $isRTL }) => $isRTL ? 'rtl' : 'ltr'};

  ${({ $breaks }) => (
    ($breaks || [])
      .map(emptySpanNums => `
        & > .TextContent-empty-span-${emptySpanNums} {
          display: block;
        }
      `)
      .join(`\n`)
  )}
`

const BackgroundContainer = styled.div`
  width: ${({ $lineWidth }) => ($lineWidth || 380) + SKETCH_TEXT_HORIZONTAL_PADDING*2}px;
  transform: scale(${({ $scale }) => $scale})
             translateX(${SKETCH_TEXT_HORIZONTAL_PADDING_FOR_DEMO - SKETCH_TEXT_HORIZONTAL_PADDING}px)
             translateY(${SKETCH_TEXT_VERTICAL_PADDING_FOR_DEMO - SKETCH_TEXT_VERTICAL_PADDING}px);
  transform-origin: top left;

  .FreehandContainer-ReplayIconButton {
    right: ${SKETCH_TEXT_HORIZONTAL_PADDING - SKETCH_TEXT_HORIZONTAL_PADDING_FOR_DEMO}px;
    top: ${SKETCH_TEXT_VERTICAL_PADDING - SKETCH_TEXT_VERTICAL_PADDING_FOR_DEMO}px;
  }
`

const SketchDemoSpot = ({
  width=350,
  ...otherProps
}) => {

  const {
    info,
    versionId,
    fromLoc,
    toLoc,
    fromWordNumber,
    toWordNumber,
  } = DEMO_HIGHLIGHT

  const { textSettings, lineWidth, svgStrokes, breaks } = info
  const { bookId, chapter: startChapter, verse: startVerse } = getRefFromLoc(fromLoc)
  const { width: windowWidth } = useAppSize()
  const [ setAnimateTimeout ] = useSetTimeout()
  const [ readyForAnimation, setReadyForAnimation ] = useState(false)

  width = getBoundedValue(width, {  max: windowWidth - 80 })
  const scale = width / ((lineWidth || 380) + SKETCH_TEXT_HORIZONTAL_PADDING_FOR_DEMO*2)

  const [ pieces ] = useVersesPieces({
    fromLoc: `${fromLoc}:${fromWordNumber}`,
    toLoc: `${toLoc}:${toWordNumber}`,
    versionId,
  })

  useEffectAsync(
    () => {
      setAnimateTimeout(() => {
        setReadyForAnimation(true)
      }, 500)
    },
    [],
  )

  return (
    <Container
      $width={width}
      $height={getBoundedValue((((breaks || []).length+1) * SKETCH_LINE_HEIGHT + SKETCH_TEXT_VERTICAL_PADDING_FOR_DEMO*2) * scale, { min: 40, max: 180 })}
      {...otherProps}
    >
      <BackgroundContainer
        $lineWidth={lineWidth}
        $scale={scale}
      >

        <TextContentContainer
          $breaks={breaks}
          $isRTL={false}
        >
          <TextContent
            pieces={pieces}
            versionId={versionId}
            bookId={bookId}
            startChapter={startChapter}
            startVerse={startVerse}
            {...(textSettings || {})}
            wrapNonSpaceTextInSpan
          />
        </TextContentContainer>

        <SketchCanvas
          svgStrokes={svgStrokes}
          animate
          readyForAnimation={readyForAnimation}
        />

      </BackgroundContainer>
    </Container>
  )
}

export default memo(SketchDemoSpot)