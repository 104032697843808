import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import TextContent from '../../common/TextContent'
import TextContentMarkupContainer from '../../markup/TextContentMarkupContainer'
import MarkupContentTextField from './MarkupContentTextField'
import DotNotes from '../../dot-notes/DotNotes'

const StyledTextContentMarkupContainer = styled(TextContentMarkupContainer)`
  /* position: relative; */
  font-size: ${({ $textFontSize }) => $textFontSize}px;
  line-height: ${({ $lineSpacing }) => $lineSpacing};

  ${({ $showVsNums }) => $showVsNums ? `` : `
    .TextContent-tag-v,
    .TextContent-tag-vp {
      display: none;
    }
  `}

  ${({ $inEditingMode, $paragraphFormatting }) => !$inEditingMode ? `` : `

    .TextContent-tag-w:hover {
      cursor: text;
      opacity: 1;
    }

    ${$paragraphFormatting !== `PARAGRAPHS-AND-HEADINGS` ? `` : `
      user-select: none;
      .TextContent-verse {
        user-select: text;
      }
    `}

  `}

`

const MarkupContent = ({
  projectId,
  isRTL,
  textFontSize,
  lineSpacing,
  showVsNumsSetting,
  showCfsSetting,
  showNotesSetting,
  showCantillationSetting,
  showHebrewVowelsSetting,
  showGreekAccentsSetting,
  showContextSetting,
  greekPunctuationSetting,
  proceedingContextSetting,
  followingContextSetting,
  inEditingMode,
  markup,
  moduleDots,
  setPlacingInfo,
  minimizedKey,
  pieces,
  versionId,
  bookId,
  startChapter,
  endChapter,
  contentRef,
  effectiveModuleWidth,
  paragraphFormatting,
  goSetPopperInfo,
}) => {

  const markupContentModuleDots = useMemo(
    () => (
      minimizedKey
        ? [ ...moduleDots ]
        : moduleDots.filter(({ positionInfo }) => positionInfo.selector !== `[data-dot-container="space-below-key"]`)
    ),
    [ moduleDots, minimizedKey ],
  )

  return (
    <>

      {!!showContextSetting.value &&
        <MarkupContentTextField
          moduleSetting={proceedingContextSetting}
          projectId={projectId}
          disabled={!inEditingMode}
          label={i18n("Preceding Context Summary")}
          data-dot-container="proceeding-context"
        />
      }

      <StyledTextContentMarkupContainer
        $textFontSize={textFontSize}
        $lineSpacing={lineSpacing}
        $showVsNums={showVsNumsSetting.value}
        $inEditingMode={inEditingMode}
        $paragraphFormatting={paragraphFormatting}
        isRTL={isRTL}
        markup={markup}
        selectMarkupOnDoubleClick
        className="MarkupContent-StyledTextContentMarkupContainer"
      >

        <TextContent
          showCfs={showCfsSetting.value}
          showNotes={showNotesSetting.value}
          showCantillation={showCantillationSetting.value}
          showHebrewVowels={showHebrewVowelsSetting.value}
          showGreekAccents={showGreekAccentsSetting.value}
          greekPunctuation={greekPunctuationSetting.value}
          pieces={pieces}
          versionId={versionId}
          bookId={bookId}
          startChapter={startChapter}
          // wordClassName="markup-text-word"
          showChapterNumbers={startChapter !== endChapter}
          ignoreClick={inEditingMode ? `word` : false}
          goSetPopperInfo={goSetPopperInfo}
        />

      </StyledTextContentMarkupContainer>

      {!!showContextSetting.value &&
        <MarkupContentTextField
          moduleSetting={followingContextSetting}
          projectId={projectId}
          disabled={!inEditingMode}
          label={i18n("Following Context Summary")}
          data-dot-container="following-context"
        />
      }

      <DotNotes
        projectId={projectId}
        moduleDots={markupContentModuleDots}
        setPlacingInfo={setPlacingInfo}
        containerRef={contentRef}
        inEditingMode={inEditingMode}
        moduleWidth={effectiveModuleWidth}
      />

    </>
  )
}

export default memo(MarkupContent)