import { memo, useContext, useRef, useState } from 'react'
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'
import { i18n } from 'inline-i18n'
import { isRTLText } from '@bibletags/bibletags-ui-helper'

import useInstanceValuesCallback from '../../hooks/useInstanceValuesCallback'
import useGoUpdateHighlight from '../../hooks/useGoUpdateHighlight'
import useVersesPieces from '../../hooks/useVersesPieces'
import useEffectAsync from '../../hooks/useEffectAsync'
import useEqualObjsMemo from '../../hooks/useEqualObjsMemo'
import useVersionInfo from '../../hooks/useVersionInfo'
import { LoggedInUserContext } from '../../context/LoggedInUser'
import { PassageGreekPunctuationContext, PassageShowCantillationContext, PassageShowCfsContext,
         PassageShowGreekAccentsContext, PassageShowHebrewVowelsContext, PassageShowNotesContext } from '../../context/LocalInfo'
import { convertMarkupModuleToHighlight } from '../../hooks/useTextSelectionMarkup'
import { getRefFromLoc } from '@bibletags/bibletags-versification'

import TextContent from '../common/TextContent'
import { getBoundedValue } from '../../utils/misc'

export const SKETCH_LINE_HEIGHT = 30
export const SKETCH_FONT_SIZE = 18
export const SKETCH_MIN_WIDTH = 290

const HiddenMeasureContainer = styled.div`
  position: absolute;
  top: 100px;
  pointer-events: none;
  white-space: nowrap;
  font-size: ${SKETCH_FONT_SIZE}px;
  line-height: ${SKETCH_LINE_HEIGHT}px;
  direction: ${({ $isRTL }) => $isRTL ? 'rtl' : 'ltr'};
`

const New = styled.span`
  position: absolute;
  right: 15px;
  top: 10px;
  background-color: ${({ theme }) => theme.palette.tertiary.main};
  color: white;
  border-radius: 3px;
  margin: -1px 0 -1px 5px;
  padding: 2px 7px;
  line-height: 14px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  vertical-align: middle;
`

const getLocWithWordNumber = ({ loc, wordNumberInVerse }) => `${loc}:${wordNumberInVerse}`

const MakeASketchMenuItem = ({
  versionId,
  selectionStart,
  selectionEnd,
  setStudyBibleItemInfo,
  toggleShowSketchInfoDialog,
}) => {

  const { hasMyPlan } = useContext(LoggedInUserContext) || {}
  const ref = useRef()

  const [ goCreateHighlight ] = useGoUpdateHighlight()

  const [ sizingInfo, setSizingInfo ] = useState({})

  const textSettings = useEqualObjsMemo({
    showNotes: useContext(PassageShowNotesContext),
    showCfs: useContext(PassageShowCfsContext),
    showCantillation: useContext(PassageShowCantillationContext),
    showHebrewVowels: useContext(PassageShowHebrewVowelsContext),
    showGreekAccents: useContext(PassageShowGreekAccentsContext),
    greekPunctuation: useContext(PassageGreekPunctuationContext),
  })

  const { bookId, chapter: startChapter, verse: startVerse } = getRefFromLoc(selectionStart.loc)

  const [ pieces ] = useVersesPieces({
    fromLoc: getLocWithWordNumber(selectionStart),
    toLoc: getLocWithWordNumber(selectionEnd),
    versionId,
  })

  const { version } = useVersionInfo(versionId) || {}
  const { languageId } = version || {}
  const isRTL = isRTLText({ languageId, bookId })

  const makeASketch = useInstanceValuesCallback(
    () => {
      if(!hasMyPlan) {
        window.getSelection().empty()
        toggleShowSketchInfoDialog({ force: true })
        return
      }
      const highlight = goCreateHighlight(
        convertMarkupModuleToHighlight({
          markupModule: {
            positionInfo: {
              start: selectionStart,
              end: selectionEnd,
            },
            type: `SKETCH`,
          },
          versionId,
          extraInfo: {
            textSettings,
            isNew: true,
            ...sizingInfo,
          },
        })
      )
      window.getSelection().empty()
      setStudyBibleItemInfo({
        highlight,
        versionId,
        anchorEl: document.body,
      })
    }
  )

  useEffectAsync(
    () => {
      const { width } = ref.current.getBoundingClientRect()
      const approxNumLines = Math.floor(Math.sqrt(width / SKETCH_LINE_HEIGHT))
      const lineWidth = getBoundedValue(Math.ceil(width / approxNumLines) + 30, { min: SKETCH_MIN_WIDTH })  // 30 is about a half word length; 200 is a minimum width
      const breaks = []
      const classNameRegex = /^TextContent-empty-span-/
      let lastLineBreakX = 0
      let lastElEmptySpanNums = ``
      ;[ ...ref.current.children ].forEach((child, idx) => {
        const { left, right } = child.getBoundingClientRect()
        if(idx === 0) {
          lastLineBreakX = isRTL ? window.innerWidth - right : left
        } else if(!isRTL && lastElEmptySpanNums && right > lastLineBreakX + lineWidth) {
          lastLineBreakX = left
          breaks.push(lastElEmptySpanNums)
        } else if(isRTL && lastElEmptySpanNums && (window.innerWidth - left) > lastLineBreakX + lineWidth) {
          lastLineBreakX = window.innerWidth - right
          breaks.push(lastElEmptySpanNums)
        }
        lastElEmptySpanNums = ([ ...child.classList ].find(className => classNameRegex.test(className)) || ``).replace(classNameRegex, ``)
      })
      setSizingInfo({
        lineWidth,
        breaks,
      })
    },
    [ pieces, textSettings, isRTL ],
  )

  return (
    <MenuItem
      onClick={makeASketch}
      tabIndex={-1}
    >
      {i18n("Make a sketch")}

      <New>{i18n("New!")}</New>

      <HiddenMeasureContainer
        ref={ref}
        $isRTL={isRTL}
      >
        <TextContent
          pieces={pieces}
          versionId={versionId}
          bookId={bookId}
          startChapter={startChapter}
          startVerse={startVerse}
          {...(textSettings || {})}
          wrapNonSpaceTextInSpan
        />
      </HiddenMeasureContainer>

    </MenuItem>
  )
}

export default memo(MakeASketchMenuItem)