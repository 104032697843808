import { memo, useCallback, useState } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { useMeasure } from 'react-use'
import Backdrop from '@material-ui/core/Backdrop'

import { IS_EMBED, MINIMUM_PASSAGE_COLUMN_WIDTH } from '../../utils/constants'
import { cloneObj, equalObjs } from '../../utils/misc'
import useInstanceValue from '../../hooks/useInstanceValue'
import useOpenPassage from '../../hooks/useOpenPassage'
import useVersionInfos from '../../hooks/useVersionInfos'
import useEffectAsync from '../../hooks/useEffectAsync'
import useSetTimeout from '../../hooks/useSetTimeout'
import useUpdatePassageBookmark from '../../hooks/useUpdatePassageBookmark'

import PassageColumn from './PassageColumn'
import PassageAddColumnButton from './PassageAddColumnButton'
import PassageBookmarks from './PassageBookmarks'
import ResizableWidthContainer from '../common/ResizableWidthContainer'
import CustomSnackbar from '../common/CustomSnackbar'

const SPECIAL_MARKUP_WAIT_SECONDS_BEFORE_FADE = 3
const SPECIAL_MARKUP_SECONDS_TO_FADE = 1.5

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  .TextContentMarkupContainer-Component {
    transition: opacity ${SPECIAL_MARKUP_SECONDS_TO_FADE}s ease-in-out;
    opacity: ${({ $markupOpacity }) => $markupOpacity};
  }
`

const ColumnsContainer = styled.div`
  flex: 1;
`

const StyledBackdrop = styled(Backdrop)`
  z-index: 14;
`

const getNumColumns = passageBookmarks => (
  passageBookmarks
    .filter(({ width }) => width)
    .length
)

export const getDefaultPassageBookmarks = () => {

  // const comfortableColumnWidth = 400

  const defaultPassageBookmarks = [
    {
      id: uuidv4(),
      width: 1,
      ref: {
        bookId: 40,
        chapter: 1,
      },
    },
  ]

  // const windowWidthWithoutDashboard = window.innerWidth - 420

  // if(windowWidthWithoutDashboard > comfortableColumnWidth * 2) {
  //   defaultPassageBookmarks.push({
  //     id: uuidv4(),
  //     width: 1,
  //     ref: {
  //       bookId: 1,
  //       chapter: 1,
  //     },
  //     versionId: 'original',
  //   })
  // }

  // if(windowWidthWithoutDashboard > comfortableColumnWidth * 3) {
  //   defaultPassageBookmarks.push({
  //     id: uuidv4(),
  //     width: 1,
  //     ref: {
  //       bookId: 40,
  //       chapter: 1,
  //     },
  //     versionId: 'original',
  //   })
  // }

  return defaultPassageBookmarks

}

const PassageArea = ({
  passageBookmarks,
  setPassageBookmarks,
  getPassageBookmarks,  // needs to be in parent so that latest scrollYFraction is retrieved
  allowMultipleColumns,
  channelIdOrUrl,
  noMiniHeader,
  // any bookmark that includes `width` indicates this is a visible passage
}) => {

  const { defaultVersionId, unhiddenSelectedVersionInfos } = useVersionInfos()
  const [ dragColumnIdx, setDragColumnIdx ] = useState(null)
  const [ snackbarInfo, setSnackbarInfo ] = useState({ open: false })
  const [ ref, { width: containerWidth } ] = useMeasure()
  const [ setRemoveSpecialMarkupTimeout, clearRemoveSpecialMarkupTimeout ] = useSetTimeout()
  const [ markupOpacity, setMarkupOpacity ] = useState(1)

  const getDragColumnIdx = useInstanceValue(dragColumnIdx)

  const onResizeComplete = useCallback(
    widths => {
      const newPassageBookmarks = cloneObj(getPassageBookmarks())
      widths.forEach((width, idx) => {
        newPassageBookmarks[idx].width = width
      })
      setPassageBookmarks(newPassageBookmarks)
    },
    [ getPassageBookmarks, setPassageBookmarks ],
  )

  const { updatePassageBookmark, getUpdatePassageBookmark } = useUpdatePassageBookmark({
    getPassageBookmarks,
    setPassageBookmarks,
    unhiddenSelectedVersionInfos,
    defaultVersionId,  
  })

  const addColumn = useCallback(
    () => {
      const newPassageBookmarks = cloneObj(getPassageBookmarks())
      const oldNumColumns = getNumColumns(newPassageBookmarks)
      const averageWidth = parseInt(newPassageBookmarks.slice(0, oldNumColumns).reduce((total, { width }) => (total + (width || 1)), 0) / oldNumColumns, 10) || 1

      const newPassageBookmark = {
        id: uuidv4(),
        width: averageWidth,
      }

      newPassageBookmarks.splice(oldNumColumns, 0, newPassageBookmark)

      setPassageBookmarks(newPassageBookmarks)
    },
    [ getPassageBookmarks, setPassageBookmarks ],
  )

  const closeColumn = useCallback(
    ({ columnIdx }) => {
      const oldPassageBookmarks = getPassageBookmarks()
      const newPassageBookmarks = [ ...oldPassageBookmarks ]
      const [ passageBookmarkToCloseFromBeingVisible ] = cloneObj(newPassageBookmarks.splice(columnIdx, 1))
      delete passageBookmarkToCloseFromBeingVisible.width
      delete passageBookmarkToCloseFromBeingVisible.showStudyBible
      newPassageBookmarks.splice(
        getNumColumns(newPassageBookmarks),
        0,
        passageBookmarkToCloseFromBeingVisible,
      )
      setPassageBookmarks(newPassageBookmarks)

      const closeSnackbar = () => setSnackbarInfo({ ...snackbarInfo, open: false })
      const snackbarInfo = {
        open: true,
        message: i18n("Column removed"),
        onClose: closeSnackbar,
        onClick: () => {
          setPassageBookmarks(oldPassageBookmarks)
          closeSnackbar()
        },
      }
      setSnackbarInfo(snackbarInfo)
    },
    [ getPassageBookmarks, setPassageBookmarks, setSnackbarInfo ],
  )

  const moveColumn = useCallback(
    moveToColumnIdx => {
      const dragColumnIdx = getDragColumnIdx()
      if(dragColumnIdx == null) return  // just in case

      setDragColumnIdx(null)

      const newPassageBookmarks = [ ...getPassageBookmarks() ]
      newPassageBookmarks.splice(
        moveToColumnIdx,
        0,
        newPassageBookmarks.splice(dragColumnIdx, 1)[0],
      )
      setPassageBookmarks(newPassageBookmarks)
    },
    [ getDragColumnIdx, setDragColumnIdx, getPassageBookmarks, setPassageBookmarks ],
  )

  const numColumns = getNumColumns(passageBookmarks)
  const enoughSpaceAvailableForAdditionalColumn = containerWidth >= (numColumns + 1) * MINIMUM_PASSAGE_COLUMN_WIDTH  // TODO
  const showAddColumnButton = !!(allowMultipleColumns && enoughSpaceAvailableForAdditionalColumn)

  const { openPassageColumnChoosers } = useOpenPassage({ getPassageBookmarks, setPassageBookmarks, numColumns })

  useEffectAsync(
    () => {
      if(containerWidth && containerWidth / numColumns < MINIMUM_PASSAGE_COLUMN_WIDTH && numColumns > 1) {
        closeColumn({ columnIdx: numColumns - 1 })
      }
    },
    [ containerWidth ],
  )

  // fade out and then remove the specialMarkup
  useEffectAsync(
    () => {
      clearRemoveSpecialMarkupTimeout()
      passageBookmarks.some(({ specialMarkup }, columnIdx) => {
        if(specialMarkup) {
          setRemoveSpecialMarkupTimeout(() => {
            setMarkupOpacity(0)
            setRemoveSpecialMarkupTimeout(() => {
              getUpdatePassageBookmark()({
                columnIdx,
                specialMarkup: null,
              })
              setMarkupOpacity(1)
            }, SPECIAL_MARKUP_SECONDS_TO_FADE * 1000)
          }, SPECIAL_MARKUP_WAIT_SECONDS_BEFORE_FADE * 1000)
          return true
        }
        return false
      })
    },
    [ passageBookmarks ],
  )

  return (
    <Container
      ref={ref}
      $markupOpacity={markupOpacity}
    >

      <ColumnsContainer>
        <ResizableWidthContainer
          onResizeComplete={onResizeComplete}
          defaultMinimumColumnWidth={MINIMUM_PASSAGE_COLUMN_WIDTH}
        >

          {Array(numColumns).fill(null).map((x, idx) => {
            const { ref, versionId=defaultVersionId, parallelVersionId, width, specialMarkup, showStudyBible=true, scrollYFraction=0, id='?' } = passageBookmarks[idx] || {}
            const { bookId=1, chapter=1 } = ref || {}

            return (
              <PassageColumn
                key={`${id} ${idx}`}  // include idx so that scroll properly reset on column reorder
                bookId={bookId}
                chapter={chapter}
                initialScrollYFraction={scrollYFraction}
                versionId={versionId}
                parallelVersionId={parallelVersionId}
                width={width}
                showStudyBible={showStudyBible && !IS_EMBED}
                columnIdx={idx}
                numColumns={numColumns}
                updatePassageBookmark={updatePassageBookmark}
                closeColumn={closeColumn}
                moveColumn={moveColumn}
                dragColumnIdx={dragColumnIdx}
                setDragColumnIdx={numColumns > 1 ? setDragColumnIdx : null}
                openPassageColumnChooser={openPassageColumnChoosers[idx]}
                specialMarkup={specialMarkup}
                setSnackbarInfo={setSnackbarInfo}
                channelIdOrUrl={channelIdOrUrl}
                noMiniHeader={noMiniHeader}
              />
            )
          })}

        </ResizableWidthContainer>
      </ColumnsContainer>

      {showAddColumnButton &&
        <PassageAddColumnButton
          addColumn={addColumn}
        />
      }

      <PassageBookmarks
        passageBookmarks={passageBookmarks}
        setPassageBookmarks={setPassageBookmarks}
      />

      <CustomSnackbar {...snackbarInfo} />

      <StyledBackdrop open={openPassageColumnChoosers.length > 1} />

    </Container>
  )
}

const ignoreScrollYChanges = (prevProps, nextProps) => (
  Object.keys(nextProps).length === Object.keys(prevProps).length
  && Object.keys(nextProps).every(key => (
    nextProps[key] === prevProps[key]
    || (
      key === "passageBookmarks"
      && (
        nextProps.passageBookmarks.every(({ scrollYFraction: ignore, ...other }={}, idx) => {
          const { scrollYFraction, ...prevOther } = (prevProps.passageBookmarks || [])[idx] || {}
          return equalObjs(other, prevOther)
        })
      )
    )
  ))
)

export default memo(PassageArea, ignoreScrollYChanges)