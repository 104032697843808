import React, { memo, useMemo } from 'react'

const PassThruPropsToChildrenWrapper = ({
  children,
  className,
  ...otherProps
}) => {

  const childrenWithAddlProps = useMemo(
    () => (
      React.Children.map(children, child => (
        React.isValidElement(child)
          ? (
            React.cloneElement(child, {
              ...otherProps,
            })
          )
          : child
      ))
    ),
    [ children, ...Object.keys(otherProps), ...Object.values(otherProps) ],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <div
      className={`${className} PassThruPropsToChildrenWrapper`}
    >
      {childrenWithAddlProps}
    </div>
  )

}

export default memo(PassThruPropsToChildrenWrapper)