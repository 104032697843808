import useShortcuts from './useShortcuts'
import keyDownSpace from '../components/common/shortcuts/sketch/keyDownSpace'
import keyDownZ from '../components/common/shortcuts/sketch/keyDownZ'

const keyDownFuncs = {
  keyDownSpace,
  keyDownZ,
}

const useSketchShortcuts = params => {

  useShortcuts({
    keyDownFuncs,
    ...params,
  })

}

export default useSketchShortcuts