import selectWholeWords from '../../../utils/selectWholeWords'

import SelectTextPopperContent from '../SelectTextPopperContent'

const selectText = async ({
  textSelectionInfo,
  highlights=[],
  closeOptions,
  getOptionsPopperInfo,
  setOptionsPopperInfo,
  goCreateStudyBibleItem,
  setSnackbarInfo,
  versionId,
  // bookId,
  onTopOfAll,
  showBSBOptions,
  showMarkupOption,
  showCopyLink,
  showAddANote,
  showAddASketch,
  setStudyBibleItemInfo,
  toggleShowNoteInfoDialog,
  toggleShowSketchInfoDialog,
}) => {

  // What it should do:
  // [MOVE Copy AND Search ELSEWHERE TO USE IN OTHER selectText funcs too]
  // - Copy (when clicked it copies immediately AND shows snackbar [which it also does when the user copies with ^C or a right click] where the user can change the checkboxes. changing checkboxes recopies)
  //   - snackbar says: "<b>Copied.</b> Includes: []Reference []Vs numbers []Footnotes []My notes":
  //     - reference [for html version, make the reference a link to Biblearc]
  //     - verse numbers
  //     - footnotes [for html version, make references in the footnotes link to Biblearc]
  //     - highlights ?

  const {
    selection,
    textRange,
    commonAncestorEl,
    knownToBeStillSelecting,
  } = textSelectionInfo

  if(
    !textRange
    || knownToBeStillSelecting
    || !commonAncestorEl.closest('.container-with-passage-popper')
    || commonAncestorEl.closest(`[contenteditable]`)
  ) {

    const { $type } = getOptionsPopperInfo()
    if($type === 'selection' || !window.getSelection().isCollapsed) {
      closeOptions()
    }

    return
  }

  const {
    firstWordEl,
    lastWordEl,
    copyText,
    searchStr,
    words,
    showSearchSuggestions,
    bookId,
    selectionStart,
    selectionEnd,
    completeVersesSelection,
  } = selectWholeWords({ selection, textSelectionInfo })

  // this block needed for search results, which do not send in versionId
  if(!versionId && firstWordEl) {
    const versionIdEl = firstWordEl.closest(`[data-versionid]`)
    if(versionIdEl) {
      versionId = versionIdEl.getAttribute(`data-versionid`)
    }
  }

  const boundingClientRect = textRange.getBoundingClientRect()
  const scrollContainerEl = onTopOfAll ? document.body : commonAncestorEl.closest('.PassageContent-ScrollContainer, .scroll-container-for-passage-popper')
  if(!scrollContainerEl) return {}
  const containerBoundingClientRect = scrollContainerEl.getBoundingClientRect()

  const pseudoElStyle = {
    top: boundingClientRect.top - containerBoundingClientRect.top + scrollContainerEl.scrollTop,
    left: boundingClientRect.left - containerBoundingClientRect.left,
    width: boundingClientRect.width,
    height: boundingClientRect.height,
  }

console.log('commonAncestorEl', commonAncestorEl)

  if(
    boundingClientRect.width === undefined
    || !firstWordEl
    || !lastWordEl
    // covers multiple search results
  ) {
    closeOptions()
    return
  }

  setOptionsPopperInfo({
    open: true,
    onClose: () => document.getSelection().empty(),
    $type: 'selection',
    isAnchoredBySelection: true,
    pseudoElStyle,
    children: (
      <SelectTextPopperContent
        closeOptions={closeOptions}
        goCreateStudyBibleItem={goCreateStudyBibleItem}
        setSnackbarInfo={setSnackbarInfo}
        versionId={versionId}
        copyText={copyText}
        searchStr={searchStr}
        words={words}
        showSearchSuggestions={showSearchSuggestions}
        bookId={bookId}
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
        firstWordEl={firstWordEl}
        lastWordEl={lastWordEl}
        completeVersesSelection={completeVersesSelection}
        highlights={highlights}
        showBSBOptions={showBSBOptions}
        showMarkupOption={showMarkupOption}
        showCopyLink={showCopyLink}
        showAddANote={showAddANote}
        showAddASketch={showAddASketch}      
        setStudyBibleItemInfo={setStudyBibleItemInfo}
        toggleShowNoteInfoDialog={toggleShowNoteInfoDialog}
        toggleShowSketchInfoDialog={toggleShowSketchInfoDialog}
      />
    ),
  })
}

export default selectText