import { memo, useContext } from 'react'
import styled from 'styled-components'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { i18n } from 'inline-i18n'
import copy from 'copy-to-clipboard'
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import { getUsfmBibleBookAbbr } from '@bibletags/bibletags-ui-helper'
import { setLocalInfo } from './PassageDisplaySettingsPopover'

import useStickyRefState from '../../hooks/useStickyRefState'
import useInstanceValuesCallback from '../../hooks/useInstanceValuesCallback'
import useGoUpdateHighlight from '../../hooks/useGoUpdateHighlight'
import fullPaletteFormattingKey from '../../utils/fullPaletteFormattingKey'
import { MAX_HIGHLIGHTS_PER_CHAPTER } from '../../hooks/useGoUpdateHighlights'
import { LoggedInUserContext } from '../../context/LoggedInUser'
import { IS_EMBED } from '../../utils/constants'
import { PassageShowHighlightsContext } from '../../context/LocalInfo'
import { convertMarkupModuleToHighlight } from '../../hooks/useTextSelectionMarkup'

import SearchSuggestionMenuItems from '../common/SearchSuggestionMenuItems'
import SearchMarkupSwitch from './SearchMarkupSwitch'
import FormattingMenuList from '../markup/FormattingMenuList'
import MyPlanPromoSpot from '../common/MyPlanPromoSpot'
import MakeASketchMenuItem from './MakeASketchMenuItem'

const Container = styled.div`
  direction: ltr;  // we do not want to inherit the rtl of the container if reading Hebrew
  min-width: ${({ $copyTextOnly }) => $copyTextOnly ? 0 : 240}px;
`

const SearchMarkupSection = styled.div`
  position: relative;
`

const MarkupNotShown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.3;
  padding: 50px 15px 75px;
  text-align: center;
  margin: 0 auto;
`

const StyledFormattingMenuList = styled(FormattingMenuList)`
  max-width: 240px;

  .FormattingMenuList-Styles {
    justify-content: center;
  }
`

const StyledMyPlanPromoSpot = styled(MyPlanPromoSpot)`
  max-width: 300px;
  padding: 25px 20px 20px;
  gap: 35px;

  .MyPlanPromoSpot-SubscribeToMessage {
    font-size: 15px;
    line-height: 1.3;
  }

  .MyPlanPromoSpot-LinkButton {
    border-color: rgba(255 255 255/.23);
    color: white;

    @media (hover: hover) {
      &:hover {
        background-color: rgba(255 255 255/.04);
      }
    }
  }

  .MyPlanPromoSpot-LinkButton img {
    filter: invert(1) hue-rotate(180deg);
  }

  .MyPlanPromoSpot-AboutSection {
    background-color: rgba(255 255 255/.05);
  }

  a {
    color: ${({ theme }) => theme.palette.secondary.light};
  }

  a:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
  }

`

const SelectTextPopperContent = ({
  closeOptions,
  goCreateStudyBibleItem,
  setSnackbarInfo,
  versionId,
  copyText,
  searchStr,
  words,
  showSearchSuggestions,
  bookId,
  selectionStart,
  selectionEnd,
  firstWordEl,
  lastWordEl,
  completeVersesSelection,
  highlights,
  showBSBOptions,
  showMarkupOption,
  showCopyLink,
  showAddANote,
  showAddASketch,
  setStudyBibleItemInfo,
  toggleShowNoteInfoDialog,
  toggleShowSketchInfoDialog,
}) => {

  const { hasMyPlan } = useContext(LoggedInUserContext) || {}

  const passageShowHighlights = useContext(PassageShowHighlightsContext)
  const turnOnPassageShowHighlights = useInstanceValuesCallback(() => setLocalInfo('passageShowHighlights', true))

  const [ searchMarkupView, setSearchMarkupView ] = useStickyRefState({ id: `SelectTextPopperContent:searchMarkupView`, defaultValue: `search` })

  showMarkupOption = !!(typeof showMarkupOption === `boolean` ? showMarkupOption : showBSBOptions) && !IS_EMBED
  showCopyLink = !!(typeof showCopyLink === `boolean` ? showCopyLink : showBSBOptions) && !IS_EMBED
  showAddANote = !!(typeof showAddANote === `boolean` ? showAddANote : showBSBOptions) && !IS_EMBED
  showAddASketch = !!(typeof showAddASketch === `boolean` ? showAddASketch : showBSBOptions) && !IS_EMBED && copyText.length > 22

  const showCreateBSBEntry = !!goCreateStudyBibleItem && !!selectionStart && !!selectionEnd && !IS_EMBED
  const copyTextOnly = !showCopyLink && !showAddANote && !showAddASketch && !showMarkupOption && !showSearchSuggestions && !showCreateBSBEntry

  // const showAnnotate = isReadingPanel && hasMyPlan
  // const showDragNote = (isReadingPanel || isSearchResults) && hasToolsPlan && inAProject

  const [ goCreateHighlight ] = useGoUpdateHighlight()

  const addANote = useInstanceValuesCallback(
    () => {
      if(!hasMyPlan) {
        window.getSelection().empty()
        toggleShowNoteInfoDialog({ force: true })
        return
      }
      const highlight = goCreateHighlight(
        convertMarkupModuleToHighlight({
          markupModule: {
            positionInfo: {
              start: selectionStart,
              end: selectionEnd,
            },
            type: `NOTE`,
          },
          versionId,
          extraInfo: {
            bibleText: copyText,
          },
        })
      )
      window.getSelection().empty()
      setStudyBibleItemInfo({
        highlight,
        versionId,
        anchorEl: document.body,
      })
    }
  )

  return (
    <Container $copyTextOnly={copyTextOnly}>

      <MenuList>

        <MenuItem
          onClick={() => copy(copyText, { format: `text/plain` })}
          tabIndex={-1}
        >
          {i18n("Copy text")}
        </MenuItem>

        {showCopyLink &&
          <MenuItem
            onClick={() => {

              const { bookId, chapter } = getRefFromLoc(selectionStart.loc)
              const searchParams = new URLSearchParams(``)
              
              if(!completeVersesSelection) {
                searchParams.set(`range`, JSON.stringify({ start: selectionStart, end: selectionEnd }))
              } else {
                searchParams.set(`range`, `${selectionStart.loc}-${selectionEnd.loc}`)
              }

              const link = `${window.location.origin}/version/${versionId}/${getUsfmBibleBookAbbr(bookId)}/${chapter}?${searchParams.toString()}`

              copy(link, { format: `text/plain` })

              const closeSnackbar = () => setSnackbarInfo({ ...snackbarInfo, open: false })
              const snackbarInfo = {
                open: true,
                message: i18n("Link copied to clipboard."),
                buttonLabel: i18n("Open Link"),
                onClick: () => window.open(link, '_blank'),
                onClose: closeSnackbar,
              }
              setSnackbarInfo && setSnackbarInfo(snackbarInfo)

              window.getSelection().empty()

            }}
            tabIndex={-1}
          >
            {i18n("Copy link to share")}
          </MenuItem>
        }

        {showAddANote &&
          <MenuItem
            onClick={addANote}
            tabIndex={-1}
          >
            {i18n("Add a note")}
          </MenuItem>
        }

        {showAddASketch &&
          <MakeASketchMenuItem
            versionId={versionId}
            selectionStart={selectionStart}
            selectionEnd={selectionEnd}
            setStudyBibleItemInfo={setStudyBibleItemInfo}
            toggleShowSketchInfoDialog={toggleShowSketchInfoDialog}
          />
        }

        {/* {inAProject && isAtLeastOneWholeVerse && (!IS_EMBED || getEmbedFullScreen()) && 
          <MenuItem
            onClick={() => {
              Show `Create a new [study module] from [ref + version]`
            }}
            tabIndex={-1}
          >
            {i18n("Study...")}
          </MenuItem>
        } */}

        {/* IMPORTANT: As I add things here, I need to update `verticalSpaceNeeded` in PassageInNotesContent */}

      </MenuList>

      {(showSearchSuggestions || showMarkupOption) &&
        <>

          <Divider />

          <SearchMarkupSection>

            {showMarkupOption &&
              <SearchMarkupSwitch
                view={showSearchSuggestions ? searchMarkupView : `markup`}
                setView={showSearchSuggestions ? setSearchMarkupView : null}
              />
            }

            {showSearchSuggestions && (!showMarkupOption || searchMarkupView === `search`) &&
              <MenuList>
                <SearchSuggestionMenuItems
                  baseSearchStr={searchStr}
                  words={words}
                  bookId={bookId}
                  onClose={closeOptions}
                  versionId={versionId}
                  selectionStart={selectionStart}
                  selectionEnd={selectionEnd}
                  tabIndex={-1}  // need to do this because of <MenuList>
                />
              </MenuList>
            }

            {showMarkupOption && (!showSearchSuggestions || searchMarkupView === `markup`) && hasMyPlan && passageShowHighlights &&
              <StyledFormattingMenuList
                formattingKeyInfo={fullPaletteFormattingKey}
                highlights={highlights}
                selectionStart={selectionStart}
                selectionEnd={selectionEnd}
                firstWordEl={firstWordEl}
                lastWordEl={lastWordEl}
                versionId={versionId}
                maxNumItems={MAX_HIGHLIGHTS_PER_CHAPTER}
              />
            }

            {showMarkupOption && (!showSearchSuggestions || searchMarkupView === `markup`) && hasMyPlan && !passageShowHighlights &&
              <MarkupNotShown>
                {i18n("Markup is currently hidden.")}
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={turnOnPassageShowHighlights}
                >
                  {i18n("Show markup")}
                </Button>
              </MarkupNotShown>
            }

            {showMarkupOption && (!showSearchSuggestions || searchMarkupView === `markup`) && !hasMyPlan &&
              <StyledMyPlanPromoSpot
                subscribeToMessage={i18n("Subscribe to markup the text with 99 style-color combos.")}
                hideAboutHeading
              />
            }

          </SearchMarkupSection>

        </>
      }

      {/* {(showAnnotate || showDragNote) &&
        <>

          <Divider />

          {showAnnotate &&
            <MenuList>

              <MenuItem
                // onClick={}
                tabIndex={-1}
                disabled
              >
                {i18n("Make a Note")}  // i.e. annotate
              </MenuItem>

            </MenuList>
          }

          {showDragNote &&
            Drag this selection into a Notes tab.
          }

        </>
      } */}

      {showCreateBSBEntry &&
        <>

          <Divider />

          <MenuList>

            <MenuItem
              onClick={() => {
                goCreateStudyBibleItem({
                  selectionStart,
                  selectionEnd,
                })
              }}
              tabIndex={-1}
            >
              {i18n("Create study Bible entry", "", "admin")}
            </MenuItem>

          </MenuList>

        </>
      }

    </Container>
  )
}

export default memo(SelectTextPopperContent)