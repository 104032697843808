import { memo, useState } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import UndoIcon from '@material-ui/icons/Undo'
import RedoIcon from '@material-ui/icons/Redo'
import DeleteIcon from '@material-ui/icons/Delete'
// import ReplayIcon from '@material-ui/icons/Replay'

import { defaultUtensilState, formattingColors, sketchUtensilInfos, utensilSizes } from '../../utils/formatting'
import useStickyRefState from '../../hooks/useStickyRefState'
import useEqualObjsMemo from '../../hooks/useEqualObjsMemo'
import useInstanceValuesCallback from '../../hooks/useInstanceValuesCallback'
import useModalAnchor from '../../hooks/useModalAnchor'
import useEffectAsync from '../../hooks/useEffectAsync'
import useSketchShortcuts from '../../hooks/useSketchShortcuts'

import FreehandContainer from './FreehandContainer'
import OptionsPopover from './OptionsPopover'
import MenuDivider from './MenuDivider'

const FORMATTING_COLORS_WITH_BLACK = [ ...formattingColors ]
FORMATTING_COLORS_WITH_BLACK[6] = `black`

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  pointer-events: ${({ $inEditingMode }) => $inEditingMode ? `all` : `none`};
  overflow: hidden;
`

const StyledIconButton = styled(IconButton)`
  color: ${({ $color, theme }) => (theme.palette[$color] || {}).main || (theme.palette.markupColors[$color] || {}).line || $color || `black`};
  padding: 10px;

  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
`

const Palette = styled.div`
  position: absolute;
  bottom: 0;
  left: 10px;
  display: flex;
  align-items: flex-end;
  user-select: none;
`

const Utensils = styled.div`
  display: flex;
  align-items: flex-end;
  margin-right: 5px;
`

const StyledFreehandContainer = styled(FreehandContainer)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const Eraser = styled.div`
  position: relative;
  width: 27px;
  height: 30px;
  margin: 0 5px;
  top: ${({ $selected }) => $selected ? `-26px` : `0`};
  transition: top .25s ease-in-out;
  cursor: ${({ $selected }) => $selected ? `default` : `pointer`};
`

const Marker = styled(Eraser)`
  width: 28px;
  height: 32px;
  cursor: pointer;
`

const Pencil = styled(Eraser)`
  width: 22px;
  height: 33px;
  margin: 0 7px;
  cursor: pointer;
`

const Highlighter = styled(Eraser)`
  width: 34px;
  height: 32px;
  margin: 0 3px;
  cursor: pointer;
`

const Utensil = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
`

const UtensilColor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 120px;
	background-repeat: no-repeat;
`

const getColor = ({ theme, $color }) => (theme.palette.markupColors[$color] || {}).arrow || $color
const getEncodedColor = params => encodeURIComponent(getColor(params))

const MarkerColor = styled(UtensilColor)`
	background-image: url('${event => (
    `data:image/svg+xml;utf8,
      <svg viewBox="0 102.616 99.619 397.384" xmlns="http://www.w3.org/2000/svg">
        <defs></defs>
        <g style="" transform="matrix(0.668102, 0, 0, 0.668102, -434.973236, 484.892731)">
          <rect x="662.419" y="-432.8" style="" width="126.386" height="48.296" fill="${getEncodedColor(event)}" />
          <path fill="${getEncodedColor(event)}" style="stroke-width: 17.9613px;" d="M 733.994 -543.46 C 724.428 -540.54 714.521 -535.76 706.648 -527.87 C 684.061 -505.28 686.764 -465.95 686.764 -465.95 C 686.764 -465.95 726.09 -463.25 748.676 -485.84 C 756.549 -493.73 761.347 -503.63 764.26 -513.18 L 733.994 -543.46 Z" transform="matrix(-0.707107, 0.707107, -0.707107, -0.707107, 881.613873, -1374.436707)"/>
        </g>
      </svg>
    `.replace(/\s*\n\s*/g, ' ')
  )}');
`

const PencilColor = styled(UtensilColor)`
	background-image: url('${event => (
    `data:image/svg+xml;utf8,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 191.456 66.764 308.544">
      <defs></defs>
      <polygon fill="${getEncodedColor(event)}" points="18.05 212.787 39.15 233.886 48.306 203.632" transform="matrix(0.707107, -0.707107, 0.707107, 0.707107, -144.968415, 87.533371)"/>
      <rect fill="${getEncodedColor(event)}" x="20.262" y="58.239" transform="matrix(0, 1, -1, 0, 121.557739, 233.410095)" width="21.825" height="60.281"/>
    </svg>
    `.replace(/\s*\n\s*/g, ' ')
  )}');
`

const HighlighterColor = styled(UtensilColor)`
	background-image: url('${event => (
    `data:image/svg+xml;utf8,
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 114.785 141.068 385.215">
        <defs></defs>
        <g transform="matrix(0.844544, 0, 0, 1, -333.993317, -287.276459)">
          <polygon fill="${getEncodedColor(event)}" points="444.468 491.315 527.154 574 507.51 593.456 424.975 510.92" transform="matrix(-0.707107, 0.707107, -0.707107, -0.707107, 1198.992432, 582.859192)"/>
          <polygon fill="${getEncodedColor(event)}" points="492.732 451.793 454.421 435.212 483.275 406.358 510.721 433.804" transform="matrix(-0.707107, 0.707107, -0.707107, -0.707107, 1127.204013, 391.246862)"/>
        </g>
      </svg>
    `.replace(/\s*\n\s*/g, ' ')
  )}');
`

const StyledOptionsPopover = styled(OptionsPopover)`

  .MuiPopover-paper {
    margin-top: -20px;
    background: ${({ theme }) => theme.palette.grey[800]};
    color: white;
    padding: 15px;
  }

  .MuiPopover-paper::before {
    border-color: ${({ theme }) => theme.palette.grey[800]} transparent transparent transparent;
  }

`

const UtensilOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 152px;
`

const UtensilOption = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 4px;
  border: 1px solid ${({ $selected }) => $selected ? `rgb(255 255 255/1)` : `rgb(255 255 255/.1)`};
  background-color: ${({ $selected }) => $selected ? `rgb(255 255 255/.1)` : `transparent`};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ $selected }) => $selected ? `rgb(255 255 255/.1)` : `rgb(255 255 255/.05)`};
      border-color: ${({ $selected }) => $selected ? `rgb(255 255 255/1)` : `rgb(255 255 255/.4)`};
    }
  }

`

const SizeOptionLine = styled.div`
  width: 30px;
  height: ${({ $size }) => Math.ceil($size * .75, 1)}px;
  border-radius: ${({ $utensil }) => ((sketchUtensilInfos[$utensil] || {}).start || {}).cap === false ? 0 : 100}px;
  background: ${({ theme, $color, $utensil }) => (theme.palette.markupColors[$color] || {})[[ `HIGHLIGHTER`, `MINIHIGHLIGHTER` ].includes($utensil) ? `highlight` : `arrow`] || $color};
`

const ColorOptionDot = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100px;
  background: ${({ theme, $color }) => (theme.palette.markupColors[$color] || {}).arrow || $color};
`

const StyledMenuDivider = styled(MenuDivider)`
  background: rgb(255 255 255/.1);
  margin: 15px -15px;
`

const EraseIconButton = styled(IconButton)`
  position: absolute;
  z-index: 1;
  top: ${({ $top }) => $top - 18}px;
  left: ${({ $left }) => $left - 18}px;
  padding: 7px;
  color: ${getColor};
  border: 1px solid ${getColor};
  background: white;
  transition: background-color .15s cubic-bezier(0.4, 0, 0.2, 1) 0ms, color .8s cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background: ${getColor};
    color: white;
  }

  .MuiSvgIcon-root {
    font-size: 20px;
  }
`

const SketchCanvas = ({
  svgStrokes,
  utensilState,
  updateSketchInfo,
  inEditingMode,
  toggleInEditingMode,
  className=``,
  ...otherProps
    // scaleAdjustment,
    // animate,
    // readyForAnimation,
    // manualMode,
}) => {

  const [ utensilStateForNew, setUtensilStateForNew ] = useStickyRefState({ id: `SketchCanvas:utensilStateForNew`, defaultValue: defaultUtensilState })

  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const [ undoRedoStackInfo, setUndoRedoStackInfo ] = useState({ steps: [[]], currentStepIdx: 0 })
  const { steps, currentStepIdx } = undoRedoStackInfo

  utensilState = useEqualObjsMemo({
    ...utensilStateForNew,
    ...(utensilState || {}),
  })

  const { selected } = utensilState

  const options = useEqualObjsMemo({
    utensil: selected,
    color: utensilState[`${selected.toLowerCase()}Color`],
    size: utensilState[`${selected.toLowerCase()}Size`],
  })

  const updateUtensilState = useInstanceValuesCallback(
    updates => {
      const newUtensilState = {
        ...utensilState,
        ...updates,
      }
      updateSketchInfo({ utensilState: newUtensilState })
      setUtensilStateForNew(newUtensilState)
    }
  )

  const clickUtensil = useInstanceValuesCallback(
    (event, utensil) => {
      if(selected === utensil) {
        if(utensil !== 'ERASER') openModal(event)
      } else {
        updateUtensilState({ selected: utensil })
      }
    }
  )

  const clickEraser = useInstanceValuesCallback(event => clickUtensil(event, 'ERASER'))
  const clickMarker = useInstanceValuesCallback(event => clickUtensil(event, 'MARKER'))
  const clickPencil = useInstanceValuesCallback(event => clickUtensil(event, 'PENCIL'))
  const clickHighlighter = useInstanceValuesCallback(event => clickUtensil(event, 'HIGHLIGHTER'))
  
  const setSize = useInstanceValuesCallback(
    event => {
      const size = parseFloat(event.target.closest(`[data-size]`).getAttribute(`data-size`))
      updateUtensilState({ [`${selected.toLowerCase()}Size`]: size })
    }
  )

  const setColor = useInstanceValuesCallback(
    event => {
      const color = event.target.closest(`[data-color]`).getAttribute(`data-color`)
      updateUtensilState({ [`${selected.toLowerCase()}Color`]: color })
    }
  )

  const updateSvgStrokes = useInstanceValuesCallback(
    (svgStrokes, skipUndoRedoStack) => {
      updateSketchInfo({ svgStrokes })
      if(!skipUndoRedoStack) {
        setUndoRedoStackInfo({
          steps: [ ...steps.slice(0, currentStepIdx + 1), svgStrokes ],
          currentStepIdx: currentStepIdx + 1,
        })
      }
    }
  )

  const undoSvgStrokes = steps[currentStepIdx - 1]
  const goUndo = useInstanceValuesCallback(
    () => {
      if(!undoSvgStrokes) return
      updateSvgStrokes(undoSvgStrokes, true)
      setUndoRedoStackInfo({ steps, currentStepIdx: currentStepIdx - 1 })
    }
  )

  const redoSvgStrokes = steps[currentStepIdx + 1]
  const goRedo = useInstanceValuesCallback(
    () => {
      if(!redoSvgStrokes) return
      updateSvgStrokes(redoSvgStrokes, true)
      setUndoRedoStackInfo({ steps, currentStepIdx: currentStepIdx + 1 })
    }
  )

  useSketchShortcuts({
    goUndo,
    goRedo,
    noneditableViewingMode: !inEditingMode,
    toggleInEditingMode,
  })

  const deleteStroke = useInstanceValuesCallback(
    event => {
      const strokeIdx = parseInt(event.target.closest(`[data-idx]`).getAttribute(`data-idx`), 10)
      const newSvgStrokes = [ ...svgStrokes ]
      if(newSvgStrokes[strokeIdx]) {
        newSvgStrokes.splice(strokeIdx, 1)
        updateSvgStrokes(newSvgStrokes)
      }
    },
  )

  useEffectAsync(
    () => {
      if((svgStrokes || []).length > 0) {
        const steps = [[]]
        svgStrokes.forEach((stroke, idx) => {
          steps.push(svgStrokes.slice(0, idx+1))
        })
        setUndoRedoStackInfo({ steps, currentStepIdx: svgStrokes.length })
      }
    },
    [],
  )

  return (
    <Container
      $inEditingMode={!!inEditingMode}
      className={`${className} SketchCanvas-Container fade-when-sibling-passage-popper-open`}
    >

      <StyledFreehandContainer
        svgStrokes={svgStrokes}
        updateSvgStrokes={!!inEditingMode && selected !== `ERASER` && updateSvgStrokes}
        options={options}
        {...otherProps}
      />

      {!!inEditingMode &&
        <Palette className="SketchCanvas-Palette">

          <Utensils className="dark-mode-exempt">

            <Eraser
              $selected={selected === 'ERASER'}
              onClick={clickEraser}
            >
              <Utensil src="/eraser.svg" />
            </Eraser>

            <Marker
              $selected={selected === 'MARKER'}
              onClick={clickMarker}
            >
              <MarkerColor
                className="dark-mode-within-dark-mode-exempt"
                $color={utensilState.markerColor}
              />
              <Utensil src="/marker.svg" />
            </Marker>

            <Pencil
              $selected={selected === 'PENCIL'}
              onClick={clickPencil}
            >
              <PencilColor
                $color={utensilState.pencilColor}
                className="dark-mode-within-dark-mode-exempt"
              />
              <Utensil src="/pencil.svg" />
            </Pencil>

            <Highlighter
              $selected={selected === 'HIGHLIGHTER'}
              onClick={clickHighlighter}
            >
              <HighlighterColor
                $color={utensilState.highlighterColor}
                className="dark-mode-within-dark-mode-exempt"
              />
              <Utensil src="/highlighter.svg" />
            </Highlighter>

          </Utensils>

          <StyledIconButton
            disabled={!undoSvgStrokes}
            onClick={goUndo}
          >
            <UndoIcon />
          </StyledIconButton>

          <StyledIconButton
            disabled={!redoSvgStrokes}
            onClick={goRedo}
          >
            <RedoIcon />
          </StyledIconButton>

        </Palette>
      }

      <StyledOptionsPopover
        anchorEl={anchorEl}
        onClose={closeModal}
        className="dark-mode-exempt"
      >

        <UtensilOptions>
          {(utensilSizes[selected] || []).map(size => (
            <UtensilOption
              key={size}
              data-size={size}
              $selected={size === utensilState[`${selected.toLowerCase()}Size`]}
              onClick={setSize}
            >
              <SizeOptionLine
                className="dark-mode-within-dark-mode-exempt"
                $size={size}
                $color={utensilState[`${selected.toLowerCase()}Color`]}
                $utensil={selected}
              />
            </UtensilOption>
          ))}
        </UtensilOptions>

        <StyledMenuDivider />

        <UtensilOptions>
          {FORMATTING_COLORS_WITH_BLACK.map(color => (
            <UtensilOption
              key={color}
              data-color={color}
              $selected={color === utensilState[`${selected.toLowerCase()}Color`]}
              onClick={setColor}
            >
              <ColorOptionDot
                className="dark-mode-within-dark-mode-exempt"
                $color={color}
                $utensil={selected}
              />
            </UtensilOption>
          ))}
        </UtensilOptions>

      </StyledOptionsPopover>

      {!!inEditingMode && selected === 'ERASER' && (svgStrokes || []).map((stroke, idx) => (
        <EraseIconButton
          key={`${idx} ${JSON.stringify(stroke).slice(0,100)}`}
          data-idx={idx}
          onClick={deleteStroke}
          $left={stroke.points[0][0]}
          $top={stroke.points[0][1]}
          $color={stroke.options.color}
        >
          <DeleteIcon />
        </EraseIconButton>
      ))}

    </Container>
  )
}

export default memo(SketchCanvas)