const SPEED_ADJUSTMENT = 1.1

const keyDownArrowUpDown = async params => {

  const {
    event,
    key,
    manualMode,
    updateManualAdvanceSpeed,
  } = params

  if(!manualMode) return

  event.preventDefault()
  updateManualAdvanceSpeed({
    speedChangeMultiplier: (
      key === 'ArrowUp'
        ? SPEED_ADJUSTMENT
        : 1/SPEED_ADJUSTMENT
    ),
  })

}

export default keyDownArrowUpDown