import React, { memo, useRef } from 'react'
import styled from 'styled-components'

import useEffectAsync from '../../hooks/useEffectAsync'

const Container = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: -1000px;
  opacity: 0;
  z-index: -1;
  user-select: none;
`

const PassageStudyBibleNotesMeasureContainer = ({
  id,
  reportHeight,
  children,
}) => {

  const ref = useRef()

  useEffectAsync(
    () => {
      const { height } = ref.current.getBoundingClientRect()
      reportHeight({ id, height })
    },
    [ id ],
  )

  return (
    <Container
      ref={ref}
    >
      {children}
    </Container>
  )
}

export default memo(PassageStudyBibleNotesMeasureContainer) 