import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import AddToQueueIcon from '@material-ui/icons/AddToQueue'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

// import { LoggedInUserContext } from '../../context/LoggedInUser'
import useSimpleToggle from '../../hooks/useSimpleToggle'

import StudyBibleFollowAddDialog from './StudyBibleFollowAddDialog'
import { IconButton } from '@material-ui/core'

const Container = styled.div`
  display: ${({ $hide }) => $hide ? `none` : `flex`};
  justify-content: center;
`

const StyledIconButton = styled(IconButton)`
  padding: 10px;
  margin-left: 6px;

  ${({ $isRTL }) => !$isRTL ? `` : `
    margin: 0 21px 0 0;
  `}

  .MuiSvgIcon-root {
    font-size: 18px;
  }
`

const StyledButton = styled(Button)`
`

const StudyBibleFollowAddButton = ({
  mini,
  isRTL,
  ...otherProps
}) => {

  // const { studyBibleFollows=[], hasMyPlan } = useContext(LoggedInUserContext) || {}
  // const { studyBibleFollows=[] } = useContext(LoggedInUserContext) || {}

  const [ showDialog, toggleShowDialog ] = useSimpleToggle()

  // const text = (
  //   hasMyPlan
  //     ? i18n("Add Channels")
  //     : i18n("Find Channels")
  // )

  const text = i18n("Add Channels")

  return (
    <>

      <Container {...otherProps} >

        {mini &&
          <Tooltip
            title={text}
          >
            <StyledIconButton
              onClick={toggleShowDialog}
              tabIndex={-1}
              $isRTL={isRTL}
            >
              <AddToQueueIcon />
            </StyledIconButton>
          </Tooltip>
        }

        {!mini &&
          <StyledButton
            onClick={toggleShowDialog}
            variant="outlined"
            size="small"
            disableElevation
            startIcon={<OndemandVideoIcon />}
            tabIndex={-1}
          >
            {text}
          </StyledButton>
        }

      </Container>

      <StudyBibleFollowAddDialog
        open={showDialog}
        onClose={toggleShowDialog}
      />

    </>
  )
}

export default memo(StudyBibleFollowAddButton)