import { useContext } from 'react'

import { LoggedInUserContext } from '../context/LoggedInUser'
import { STUDY_BIBLE_CHANNELS } from '../utils/constants'
import useDataQuery from './useDataQuery'

import channelItemsQuery from '../graphql/queries/channelItems'
import customStudyBibleItemsQuery from '../graphql/queries/customStudyBibleItems'

const DEFAULT_STUDY_BIBLE_FOLLOWS = [{ ...STUDY_BIBLE_CHANNELS[0] }]

const useStudyBibleChannelItems = ({ chapterLoc, channelIdOrUrl }) => {

  let { hasMyPlan, studyBibleFollows } = useContext(LoggedInUserContext) || {}

  if(!hasMyPlan) {
    studyBibleFollows = DEFAULT_STUDY_BIBLE_FOLLOWS
  }

  const getQueryInfo = idx => {
    let channelId, channelUrl

    if(channelIdOrUrl) {
      if(idx === 0) {
        if(/^https:\/\//.test(channelIdOrUrl)) {
          channelUrl = channelIdOrUrl
        } else {
          channelId = channelIdOrUrl
        }
      }
    } else {
      const studyBibleFollow = studyBibleFollows[idx] || {}
      channelUrl = studyBibleFollow.channelUrl
      if(!channelUrl) {
        channelId = studyBibleFollow.channelId
      }
    }

    return {
      channelItemsQuery,
      variables: {
        channelId,
        channelUrl,
        chapterLoc,
      },
      skip: !chapterLoc || (!channelUrl && !channelId),
    }
  }

  let loading = false
  const getValue = (queryValue, idx) => {
    loading = loading || queryValue.loading
    const { channelItems } = queryValue
    return (
      (channelItems || [])
        .map(channelItem => {

          const [ fromLoc, toLoc ] = ((channelItem.info || {}).locSets || []).find(([ fromLoc, toLoc ]) => (
            fromLoc.slice(0,5) <= chapterLoc
            && (toLoc || fromLoc).slice(0,5) >= chapterLoc
          )) || []

          let studyBibleFollow = studyBibleFollows[idx]

          if(channelIdOrUrl) {
            if(/^https:\/\//.test(channelIdOrUrl)) {
              studyBibleFollow = { id: `none`, channelUrl: channelIdOrUrl }
            } else {
              studyBibleFollow = { id: `none`, channelId: channelIdOrUrl }
            }
          }

          return {
            ...channelItem,
            type: `CHANNEL`,
            studyBibleFollow,
            fromLoc,
            toLoc,
          }
        })
        .filter(({ fromLoc }) => fromLoc)
    )
  }

  let idx = -1
  const studyBibleChannelItems = [
    ...getValue(useDataQuery(getQueryInfo(++idx)), idx),
    ...getValue(useDataQuery(getQueryInfo(++idx)), idx),
    ...getValue(useDataQuery(getQueryInfo(++idx)), idx),
    ...getValue(useDataQuery(getQueryInfo(++idx)), idx),
    ...getValue(useDataQuery(getQueryInfo(++idx)), idx),
    ...getValue(useDataQuery(getQueryInfo(++idx)), idx),
    ...getValue(useDataQuery(getQueryInfo(++idx)), idx),
    ...getValue(useDataQuery(getQueryInfo(++idx)), idx),
    ...getValue(useDataQuery(getQueryInfo(++idx)), idx),
    ...getValue(useDataQuery(getQueryInfo(++idx)), idx),
    ...getValue(useDataQuery(getQueryInfo(++idx)), idx),
    ...getValue(useDataQuery(getQueryInfo(++idx)), idx),
    ...getValue(useDataQuery(getQueryInfo(++idx)), idx),
    ...getValue(useDataQuery(getQueryInfo(++idx)), idx),
    ...getValue(useDataQuery(getQueryInfo(++idx)), idx),
  ]

  const channelIds = studyBibleFollows.filter(({ channel }) => channel).map(({ channel }) => channel.id)
  const { customStudyBibleItems: customStudyBibleItemsAndCount={} } = useDataQuery({
    customStudyBibleItemsQuery,
    variables: {
      query: chapterLoc,
      channelId: channelIds,
    },
    skip: !chapterLoc || channelIds.length === 0,
  })

  studyBibleChannelItems.push(...(customStudyBibleItemsAndCount.customStudyBibleItems || []))

  return {
    studyBibleChannelItems,
    loading,
  }
}

export default useStudyBibleChannelItems