export const operationsToBlock = [
  `userUpdate`,
  `user`,
  `highlights`,
  `stripeSubscriptionChangePreview`,
  `stripeSubscriptionNextInvoice`,
  `groupSubscriptions`,
  `stats`,
  `myTagSetSubmissionStatsByVersionIdAndBookId`,
  `taggingEditorInfoByLanguageId`,
  `myTagSetSubmissions`,
  `versionProgressByBookId`,
  `nextAndPreviousLocsWithoutTagging`,
  `users`,
  `searchShared`,
  `moduleHistoryItems`,
  `moduleByProjectHistoryItems`,
  `projectPassageHistoryItems`,
  `accountSettings`,
  `accountSetting`,
  `alertsItems`,
  `projects`,
  `folders`,
  `folderAncestry`,
  `tags`,
  `updateHighlights`,
  `deleteHighlights`,
  `notificationsDevice`,
  `updateNotificationsDevice`,
  `submitTagSet`,
]

export const operationsToGetFromEmbed = [
  `formattingKeys`,
  `project`,
  `modules`,
  `module`,
  `moduleSetting`,
  `modulePieces`,
  `modulePiece`,
  `moduleDots`,
  `moduleDot`,
  `moduleMarkups`,
  `moduleMarkup`,
]

export const operationsToSaveToEmbed = [
  `updateProject`,
  `updateModule`,
  `deleteModule`,
  `updateModuleByProject`,
  `deleteModuleByProject`,
  `updateModulePassages`,
  `deleteModulePassages`,
  `setModuleSetting`,
  `updateModulePieces`,
  `deleteModulePieces`,
  `updateModuleDots`,
  `deleteModuleDots`,
  `updateModuleMarkups`,
  `deleteModuleMarkups`,
  `updateFormattingKey`,
  `deleteFormattingKey`,
]