import { useContext, useMemo } from 'react'
import { PassageShowHighlightsContext } from '../context/LocalInfo'
import useIsLoggedIn from './useIsLoggedIn'
import useDataQuery from './useDataQuery'

import highlightsQuery from '../graphql/queries/highlights'
import { getChapterHighlightsVariables } from './useGoUpdateHighlights'

const useHighlights = ({
  versionId,
  parallelVersionId,
  bookId,
  chapter,
  showStudyBible,
  noHighlights,
}) => {

  const isLoggedIn = useIsLoggedIn()
  const passageShowHighlights = useContext(PassageShowHighlightsContext)

  const skipBoth = !!noHighlights || !isLoggedIn || (!passageShowHighlights && !showStudyBible)

  const { highlights: { highlights }={} } = useDataQuery({
    highlightsQuery,
    variables: getChapterHighlightsVariables({ versionId, bookId, chapter }),
    skip: skipBoth,  // user still able to view highlights, notes, and sketches, even without active MyBSB sub
    dataOnError: null,
  })

  const { highlights: { highlights: highlights2 }={} } = useDataQuery({
    highlightsQuery,
    variables: getChapterHighlightsVariables({ versionId: parallelVersionId, bookId, chapter }),
    skip: skipBoth || !parallelVersionId,
    dataOnError: null,
  })

  const toReturn = useMemo(
    () => {
      const nonNoteOrSketchHighlights = (passageShowHighlights && highlights) ? highlights.filter(({ info: { type } }) => ![ `NOTE`, `SKETCH` ].includes(type)) : null
      let notesAndSketches = (showStudyBible && highlights) ? highlights.filter(({ info: { type } }) => [ `NOTE`, `SKETCH` ].includes(type)) : null

      if(highlights2) {
        notesAndSketches = notesAndSketches || []
        notesAndSketches.push(...highlights2.filter(({ info: { type } }) => [ `NOTE`, `SKETCH` ].includes(type)))
      }

      return { nonNoteOrSketchHighlights, notesAndSketches }
    },
    [ highlights, highlights2, passageShowHighlights, showStudyBible ],
  )

  return toReturn

}

export default useHighlights