import { memo, useCallback, useContext } from 'react'
import { i18n } from 'inline-i18n'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components'
import AddIcon from '@material-ui/icons/Add'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'

import {
  IS_EMBED,
  MINIMUM_MEDIUM_WIDTH,
} from '../../../utils/constants'
import { getNewOrdering } from '../../../utils/misc'
import useGoUpdateModule from '../../../hooks/useGoUpdateModule'
import useModalAnchor from '../../../hooks/useModalAnchor'
import { LoggedInUserContext } from '../../../context/LoggedInUser'
import { getEmbedMode } from '../../../graphql/links/embedLink'

import ModuleThumbnailContainer from './ModuleThumbnailContainer'
import OptionsPopover from '../../common/OptionsPopover'

const Container = styled(ModuleThumbnailContainer)`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  transition: background-color .2s ease-in-out;

  ${({ $disabled, theme }) => !$disabled ? `` : `
    color: ${theme.palette.grey[400]};
  `}

  ${({ $disabled, theme }) => $disabled ? `` : `
    &:hover {
      cursor: pointer;
      background-color: ${theme.palette.grey[300]};
    }
  `}
`

const StyledAddIcon = styled(AddIcon)`
  display: block;

  @media (max-width: ${MINIMUM_MEDIUM_WIDTH}px) {
    height: 18px;
  }
`

const CreateMessage = styled.div`
  padding: 3px 12px 7px;
  color: ${({ theme }) => theme.palette.grey[500]};
`

// const New = styled.div`
//   padding: 0 3px;
//   line-height: 13px;
//   background: ${({ theme }) => theme.palette.tertiary.dark};
//   color: white;
//   border-radius: 2px;
//   font-size: 8px;
//   font-weight: bold;
//   text-transform: uppercase;
//   margin-left: 5px;
// `

const NewModuleThumbnail = ({
  projectId,
  goUpdateProject,
  showInDoubleStudyMode,
  nonNotesModuleByProjects,
  embedSettings,
}) => {

  const user = useContext(LoggedInUserContext)
  const { hasToolsPlan } = user || {}

  const [ goCreateModule ] = useGoUpdateModule({ projectId })

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const lastNonNotesModuleOrdering = (nonNotesModuleByProjects.at(-1) || {}).ordering
  const numNonNotesModules = nonNotesModuleByProjects.length

  const defaultColumn = (
    (
      showInDoubleStudyMode
      && numNonNotesModules >= 1
    )
      ? 2
      : 1
  )

  const createNewModule = useCallback(
    ({ type, initialLegacySettings, column=defaultColumn }) => {

      closeModal()

      const id = uuidv4()
      const ordering = getNewOrdering(lastNonNotesModuleOrdering)

      // // write this query so that it receives an immediate result
      // client.writeQuery({
      //   query: modulePiecesQuery,
      //   data: {
      //     modulePieces: {
      //       modulePieces: [],
      //       count: 0,
      //     },
      //   },
      //   variables: {
      //     moduleId: id,
      //   },
      // })

      goCreateModule(
        {
          id,
          type,
        },
        { ordering },
        { initialLegacySettings },
      )

      goUpdateProject({ [`studyModule${column}Id`]: id })

    },
    [ lastNonNotesModuleOrdering, goCreateModule, goUpdateProject, defaultColumn, closeModal ],
  )

  const createArc = useCallback(() => createNewModule({ type: `DISCOURSE`, initialLegacySettings: { arcorbracket: `a` } }), [ createNewModule ])
  const createBracket = useCallback(() => createNewModule({ type: `DISCOURSE`, initialLegacySettings: { arcorbracket: `b` } }), [ createNewModule ])
  const createPhrase = useCallback(() => createNewModule({ type: `PHRASING` }), [ createNewModule ])
  const createDiagram = useCallback(() => createNewModule({ type: `DIAGRAMMING` }), [ createNewModule ])
  const createOutline = useCallback(() => createNewModule({ type: `OUTLINE` }), [ createNewModule ])
  const createMarkup = useCallback(() => createNewModule({ type: `MARKUP` }), [ createNewModule ])

  const disabled = !!(IS_EMBED && getEmbedMode() === `frozen` && !embedSettings.frozen && embedSettings.disabled && embedSettings.allowStudentsToAddModules && !embedSettings.viewOnly)

  if(!hasToolsPlan && !IS_EMBED) return null
  if(IS_EMBED && getEmbedMode() === `edit` && !embedSettings.allowStudentsToAddModules) return null
  if(IS_EMBED && getEmbedMode() === `frozen` && !disabled) return null

  return (
    <>

      <Container
        onClick={disabled ? null : openModal}
        $disabled={disabled}
      >
        <StyledAddIcon />
      </Container>

      <OptionsPopover
        anchorEl={anchorEl}
        onClose={closeModal}
      >
        <MenuList>
          <CreateMessage>
            {i18n("Create a new...")}
          </CreateMessage>
          <MenuItem
            onClick={createArc}
          >
            {i18n("Arc")}
          </MenuItem>
          <MenuItem
            onClick={createBracket}
          >
            {i18n("Bracket")}
          </MenuItem>
          <MenuItem
            onClick={createPhrase}
          >
            {i18n("Phrase")}
          </MenuItem>
          <MenuItem
            onClick={createDiagram}
          >
            {i18n("Diagram")}
          </MenuItem>
          <MenuItem
            onClick={createOutline}
          >
            {i18n("Outline")}
          </MenuItem>
          <MenuItem
            onClick={createMarkup}
          >
            {i18n("Markup")}
            {/* <New>
              {i18n("New!")}
            </New> */}
          </MenuItem>
        </MenuList>
      </OptionsPopover>

    </>
  )
}

export default memo(NewModuleThumbnail)