import { memo, useCallback, useRef, useMemo, useState } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import CloseIcon from '@material-ui/icons/Close'
import ChatIcon from '@material-ui/icons/Chat'
import LaunchIcon from '@material-ui/icons/Launch'
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import ShareIcon from '@material-ui/icons/Share'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Button from '@material-ui/core/Button'
import { i18n } from 'inline-i18n'

import useModalAnchor from '../../hooks/useModalAnchor'
import useMediaSeek from '../../hooks/useMediaSeek'
import useInstanceValue from '../../hooks/useInstanceValue'
import useEffectAsync from '../../hooks/useEffectAsync'
import useModifierKeyDown from '../../hooks/useModifierKeyDown'
import useShiftKeyDown from '../../hooks/useShiftKeyDown'
import useIsLoggedIn from '../../hooks/useIsLoggedIn'
import useLogInCallback from '../../hooks/useLogInCallback'
import { HAS_FULLSCREEN_OPTION, getTimeStringFromSeconds, preventDefaultEvent } from '../../utils/misc'
import { fontFamily } from '../../utils/theme'

import OptionsPopover from './OptionsPopover'
import NavLinkOrAWithDisable from './NavLinkOrAWithDisable'
import VideoPlayerCustomControlsBookmarking from './VideoPlayerCustomControlsBookmarking'
import VideoPlayerCustomControlsSharing from './VideoPlayerCustomControlsSharing'
import Loading from './Loading'
import VideoAudioSwitch from './VideoAudioSwitch'
import useSetTimeout from '../../hooks/useSetTimeout'
import FadedLoading from './FadedLoading'

const Container = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background: ${({ $lessDark }) => `linear-gradient(rgb(0 0 0/${$lessDark ? .7 : 1}) 0%, rgb(0 0 0/${$lessDark ? .4 : .7}) ${$lessDark ? `90px` : `45%`}, rgb(0 0 0/${$lessDark ? .4 : .7}) ${$lessDark ? `120px` : `55%`}, rgb(0 0 0/${$lessDark ? .7 : 1}) ${$lessDark ? `210px` : `100%`})`};
  color: white;
  opacity: ${({ $show }) => $show ? 1 : 0};
  transition: .35s ease-in-out opacity;
`

const StyledOptionsPopover = styled(OptionsPopover)`
  z-index: 1500 !important;
`

const StyledIconButton = styled(IconButton)`
  padding: 8px;
  margin: 0;
  color: ${({ $yellow, theme }) => $yellow ? theme.palette.primary.light : `white`};

  &.Mui-disabled {
    color: rgb(255 255 255/.3);
  }

  .MuiSvgIcon-root {
    font-size: 20px;
  }

  @media (hover: hover) {
    &:hover {
      background: rgb(255 255 255/.2);
    }
    &:hover img {
      opacity: 1;
    }
  }
`

const BigStyledIconButton = styled(StyledIconButton)`
  padding: 6px;

  .MuiSvgIcon-root {
    font-size: 24px;
  }

  .MuiTouchRipple-root {
    display: none;
  }

`

const BookmarkIconButton = styled(IconButton)`
  position: absolute;
  z-index: 2;
  top: -3px;
  transform: translateX(-12px);
  left: ${({ $leftPercentage }) => $leftPercentage}%;
  padding: 3px;

  ${({ $seeking, $selected, theme }) => (
    $selected
      ? `
        .MuiSvgIcon-root {
          transform: scale(1.8);
          color: ${theme.palette.primary.light};
        }
      `
      : ($seeking ? `` : `
        &:hover .MuiSvgIcon-root {
          transform: scale(1.4);
        }
      `)
  )};

  .MuiSvgIcon-root {
    font-size: 18px;
    color: ${({ $isMine, theme }) => $isMine ? theme.palette.primary.main : `rgb(255 255 255/.3)`};
    transition: transform .15s ease-in-out;
  }

`

const StyledLoading = styled(Loading)`
  background: none;
  position: relative;
  width: 24px;
  height: 24px;
`

const IconLikeText = styled.div`
  font-size: 15px;
  height: 20px;
  width: 40px;
  margin: 0 -10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: center;
`

const Spacer = styled.div`
  flex: 1;
`

const Main = styled.div`
  position: relative;
  min-height: 0;
  flex: 1;
  display: flex;
  overflow: hidden;
  opacity: ${({ $show }) => $show ? 1 : 0};
  transition: .35s ease-in-out opacity;
`

const Side = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 5px;
`

const Title = styled.div`
  min-height: 0;
  overflow: hidden;
  flex: 1;
  cursor: pointer;
  margin-bottom: 20px;
  -webkit-tap-highlight-color: transparent;
`

const Bottom = styled.div`
  position: relative;
  z-index: 1;
  height: 51px;
  margin-top: -1px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px;
  gap: 5px;
  opacity: ${({ $show }) => $show ? 1 : 0};
  transition: .35s ease-in-out opacity;
`

const ProgressBar = styled.div`
  height: 17px;
  position: absolute;
  left: 10px;
  right: 10px;
  top: 0;
  cursor: pointer;
  margin: -8px 0;

  &::before {
    content: "";
    background: rgb(255 255 255/.25);
    border-radius: 10px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 8px;
    top: 8px;
  }
`

const Progress = styled.div`
  background: rgb(255 255 255);
  position: absolute;
  left: 0;
  bottom: 6px;
  top: 6px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0 0 0/.5);
`

const HoverProgress = styled.div`
  background: ${({ $seeking }) => $seeking ? `white` : `rgb(255 255 255/.25)`};
  z-index: ${({ $seeking }) => $seeking ? 3 : 1};
  position: absolute;
  top: 8px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transform: translate(-9px, -9px);
`

const Time = styled.div`
  font-weight: 300;
`

const NumMyBookmarks = styled.div`
  position: absolute;
  top: 0;
  bottom: 2px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-family: ${fontFamily};
  font-size: 11px;
  font-weight: bold;
`

const AddSame = styled.div`
  position: relative;
`

const AddSameButton = styled(Button)`
  margin: 3px 0;
`

const AddSameCloseIconButton = styled(IconButton)`
  position: absolute;
  top: -30px;
  right: -33px;
  color: white;
  background: transparent;
  padding: 15px;

  .MuiSvgIcon-root {
    font-size: 16px;
    background: rgb(0 0 0/.7);
    padding: 3px;
    border-radius: 20px;
    transition: transform .15s ease-in-out;
  }

  &:hover .MuiSvgIcon-root {
    background: black;
    transform: scale(1.2);
  }

`

const iconSize = 100
const titlePlayPauseIconBaseStyles = `
  pointer-events: none;
  position: absolute;
  color: rgb(255 255 255/.25);
  width: ${iconSize}px;
  height: ${iconSize}px;
  top: calc(50% - ${iconSize/2}px);
  left: calc(50% - ${iconSize/2}px);
  margin-top: 10px;
  transition: .35s ease-in-out opacity, .07s ease-in-out color, .35s ease-in-out transform;
`

const TitlePlayArrowIcon = styled(PlayArrowIcon)`
  ${titlePlayPauseIconBaseStyles}
  transform: scale(${({ $playing }) => $playing ? 5 : 1});
  opacity: ${({ $playing }) => $playing ? 0 : 1};
  color: ${({ $playing }) => $playing ? `white` : `rgb(255 255 255/.25)`};
`

const TitlePauseIcon = styled(PauseIcon)`
  ${titlePlayPauseIconBaseStyles}
  transform: scale(${({ $playing }) => $playing ? 1 : .3});
  opacity: ${({ $playing }) => $playing ? 1 : 0};
  color: ${({ $playing }) => $playing ? `rgb(255 255 255/.25)` : `white`};
`

const SourceIcon = styled.img`
  width: 20px;
  height: 20px;
  opacity: .75;
  transition: .15s ease-in-out opacity;
`

const VideoPlayerCustomControls = ({
  customControls,  // all items in this section only relevant if `customControls` is true
  onClose,
  sourceLink,
  studyBibleLink,
  reactions,
  addBookmark,
  removeBookmark,
  updateBookmark,
  numComments,
  updateMyBookmarks,
  title,
  playing,
  onPlay,
  onPause,
  duration,
  currentSecs,
  buffering,
  speed=1,
  setSpeed,
  toggleFullscreen,
  togglePip,
  goSeek,
  isAudio,
  lessDark,
  showControlsProps,
  setDoAudio,
  hasAudio,
  showSharing,
  toggleShowSharing,
  showBookmarking,
  toggleShowBookmarking,
  sharingTitle,
  sharingText,
  ...otherProps
}) => {

  const isLoggedIn = useIsLoggedIn()
  const { logIn, navigatingToLogin } = useLogInCallback()
  const focusPlayButton = useCallback(() => playPauseRef.current.focus(), [])
  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const [ myReactionTypeIndexInEdit, setMyReactionTypeIndexInEdit ] = useState(null)
  const clearMyReactionTypeIndexInEdit = useCallback(() => setMyReactionTypeIndexInEdit(null), [ setMyReactionTypeIndexInEdit ])
  const { hoverProgressProps, ...seekProps } = useMediaSeek({ goSeek, focusPlayButton, duration, clear: clearMyReactionTypeIndexInEdit })
  const playPauseRef = useRef()
  const getCurrentSecs = useInstanceValue(currentSecs)
  const [ addSameTooltipMs, setAddSameTooltipMs ] = useState(null)
  const [ setAddSameTimeout, clearAddSameTimeout ] = useSetTimeout()
  const [ modifierKeyDown, getModifierKeyDown ] = useModifierKeyDown()
  const [ shiftKeyDown, getShiftKeyDown ] = useShiftKeyDown()

  const getMyReactionTypes = useInstanceValue((reactions || {}).myReactionTypes || [])

  const sourceIcon = {
    "https://beta.sermonaudio.com": `/sa_white.svg`,
    "https://www.desiringgod.org": `/dg_white.svg`,
  }[(sourceLink || ``).match(/^https?:\/\/[^/]+/) || ``]

  const {
    numMyBookmarks,
    bookmarkMssToShow,
  } = useMemo(
    () => {

      const getMs = key => parseInt(key.split(`:`)[1], 10)
      const numMyBookmarks = getMyReactionTypes().length
      // const totalNumBookmarks = Object.keys(reactions || {}).filter(type => type !== `myReactionTypes`).reduce((total, type) => total + reactions[type], 0)
      const bookmarkMssSortedByProminence = Object.keys(reactions || {}).filter(type => type !== `myReactionTypes`).sort((a,b) => reactions[a] - reactions[b]).map(getMs)
      const bookmarkMssToShow = [ ...getMyReactionTypes() ].map(getMs).sort((a,b) => a-b)
      const pixelsNeededForEachBookmark = 24
      const pixelsPerSecond = 380 / duration
      const minDiffInMs = (pixelsNeededForEachBookmark / pixelsPerSecond) * 1000
      bookmarkMssSortedByProminence.forEach(bookmarkMs => {
        if(bookmarkMssToShow.length >= 7) return
        const insertIdx = (
          bookmarkMssToShow.length === 0
            ? 0
            : (
              [ ...bookmarkMssToShow, 999999999 ].findIndex((bMs, idx) => (
                (idx === 0 || bookmarkMssToShow[idx-1] < bookmarkMs)
                && bMs > bookmarkMs
                && bookmarkMs - (idx === 0 ? -minDiffInMs : bookmarkMssToShow[idx-1]) >= minDiffInMs
                && bMs - bookmarkMs >= minDiffInMs
              ))
            )
        )
        if(insertIdx !== -1) {
          bookmarkMssToShow.splice(insertIdx, 0, bookmarkMs)
        }
      })

      return {
        numMyBookmarks,
        bookmarkMssToShow,
      }
    },
    [ reactions, duration, getMyReactionTypes ],
  )

  const pauseAndClose = useCallback(
    () => {
      if(playing) onPause()
      onClose()
    },
    [ playing, onPause, onClose ],
  )

  const goUpdateBookmark = useCallback(
    ({ event, directionMultiple=1 }) => {
      if(myReactionTypeIndexInEdit == null) return

      // TODO: When two of my bookmarks pass each other
      preventDefaultEvent(event)
      const oldMs = parseInt(getMyReactionTypes()[myReactionTypeIndexInEdit].split(`:`)[1], 10)
      const stepInMs = (getShiftKeyDown() ? 10000 : (getModifierKeyDown() ? 100 : 1000))
      const newMs = oldMs + stepInMs * directionMultiple
      updateBookmark({ oldMs, newMs })
      goSeek(newMs / 1000)

    },
    [ updateBookmark, myReactionTypeIndexInEdit, getMyReactionTypes, getModifierKeyDown, getShiftKeyDown, goSeek ],
  )

  const onKeyDown = useCallback(
    event => {
      const stepInSec = (getShiftKeyDown() ? 30 : (getModifierKeyDown() ? 1 : 5))
      if(event.key === `ArrowRight`) {
        if(showBookmarking && myReactionTypeIndexInEdit != null) {
          goUpdateBookmark({ event })
        } else {
          event.preventDefault()
          goSeek(Math.min(getCurrentSecs() + stepInSec, duration))
        }
      } else if(event.key === `ArrowLeft`) {
        if(showBookmarking && myReactionTypeIndexInEdit != null) {
          goUpdateBookmark({ event, directionMultiple: -1 })
        } else {
          event.preventDefault()
          goSeek(Math.max(getCurrentSecs() - stepInSec, 0))
        }
      } else if(event.key === `Escape`) {
        event.preventDefault()
        if(showBookmarking) {
          if(myReactionTypeIndexInEdit != null) {
            clearMyReactionTypeIndexInEdit()
          } else {
            toggleShowBookmarking()
          }
        } else if(showSharing) {
          toggleShowSharing()
        } else if(onClose) {
          onClose()
        }
      }
    },
    [ goSeek, getCurrentSecs, duration, onClose, showBookmarking, toggleShowBookmarking, showSharing, toggleShowSharing, myReactionTypeIndexInEdit, clearMyReactionTypeIndexInEdit, goUpdateBookmark, getModifierKeyDown, getShiftKeyDown ],
  )

  const onClickBookmark = useCallback(
    event => {
      preventDefaultEvent(event)
      const bookmarkMs = parseInt(event.target.closest(`[data-bookmark-ms]`).getAttribute(`data-bookmark-ms`), 10)
      goSeek(bookmarkMs / 1000)
      const index = getMyReactionTypes().indexOf(`BOOKMARK:${bookmarkMs}`)
      if(showBookmarking) {
        if(index !== -1) {
          setMyReactionTypeIndexInEdit(index)
        } else {
          clearMyReactionTypeIndexInEdit()
        }
      } else if(index === -1) {
        setAddSameTooltipMs(bookmarkMs)
        setAddSameTimeout(() => setAddSameTooltipMs(null), 1000*20)
      }
    },
    [ goSeek, showBookmarking, setMyReactionTypeIndexInEdit, getMyReactionTypes, clearMyReactionTypeIndexInEdit, setAddSameTimeout ],
  )

  const clearAddSame = useCallback(
    () => {
      setAddSameTooltipMs(null)
      clearAddSameTimeout()
    },
    [ setAddSameTooltipMs, clearAddSameTimeout ],
  )

  const addSame = useCallback(
    () => {
      addBookmark(addSameTooltipMs)
      clearAddSame()
    },
    [ addBookmark, addSameTooltipMs, clearAddSame ],
  )

  useEffectAsync(
    () => {
      setTimeout(() => {
        try {
          // this will occasionally cause an error, so we wrap it in a try/catch
          playPauseRef.current.focus()
        } catch(err) {}
      }, 50)
    },
    [],
  )

  const BookmarkishIcon = numMyBookmarks ? BookmarkIcon : BookmarkBorderIcon

  return (
    <Tooltip
      open={addSameTooltipMs != null}
      placement="top"
      interactive
      title={
        <AddSame>
          <AddSameButton
            tabIndex={-1}
            variant="contained"
            color="primary"
            disableElevation
            size="small"
            onClick={isLoggedIn ? addSame : logIn}
            startIcon={isLoggedIn ? <BookmarkIcon /> : null}
          >
            {isLoggedIn ? i18n("Add the same bookmark") : i18n("Sign in to add your own bookmarks")}
            {navigatingToLogin && <FadedLoading size={15} />}
          </AddSameButton>
          <AddSameCloseIconButton
            onClick={clearAddSame}
          >
            <CloseIcon />
          </AddSameCloseIconButton>
        </AddSame>
      }
    >
      <Container
        onClick={focusPlayButton}
        {...showControlsProps}
        {...((isAudio || showBookmarking || showSharing) ? { $show: true } : {})}
        $lessDark={isAudio || lessDark}
        {...otherProps}
      >

        {!!addBookmark &&
          <VideoPlayerCustomControlsBookmarking
            show={showBookmarking}
            getMyReactionTypes={getMyReactionTypes}
            addBookmark={addBookmark}
            removeBookmark={removeBookmark}
            updateBookmark={updateBookmark}
            getCurrentSecs={getCurrentSecs}
            myReactionTypeIndexInEdit={myReactionTypeIndexInEdit}
            setMyReactionTypeIndexInEdit={setMyReactionTypeIndexInEdit}
            clearMyReactionTypeIndexInEdit={clearMyReactionTypeIndexInEdit}
            goUpdateBookmark={goUpdateBookmark}
            toggleShowBookmarking={toggleShowBookmarking}
            modifierKeyDown={modifierKeyDown}
            shiftKeyDown={shiftKeyDown}
          />
        }

        {!!sharingTitle &&
          <VideoPlayerCustomControlsSharing
            show={showSharing}
            sourceLink={sourceLink}
            studyBibleLink={studyBibleLink}
            toggleShowSharing={toggleShowSharing}
            sharingTitle={sharingTitle}
            sharingText={sharingText}
          />
        }

        <Main $show={!showSharing && !showBookmarking}>
          <Side>

            {!!reactions &&
              <Tooltip
                placement="top"
                title={
                  numMyBookmarks > 0
                    ? i18n("Edit bookmarks")
                    : i18n("Add a bookmark")
                }
              >
                <BigStyledIconButton
                  tabIndex={-1}
                  onClick={toggleShowBookmarking}
                  $yellow={numMyBookmarks > 0}
                  className="VideoPlayerCustomControls-createAndEditBookmarks"
                >
                  <BookmarkishIcon />
                  {numMyBookmarks > 0 && <NumMyBookmarks>{numMyBookmarks}</NumMyBookmarks>}
                </BigStyledIconButton>
              </Tooltip>
            }

            {!!studyBibleLink &&
              <NavLinkOrAWithDisable
                tabIndex={-1}
                to={studyBibleLink}
              >
                <StyledIconButton tabIndex={-1}>
                  <ChatIcon />
                </StyledIconButton>
              </NavLinkOrAWithDisable>
            }

            {!!studyBibleLink &&
              <StyledIconButton
                tabIndex={-1}
                onClick={toggleShowSharing}
              >
                <ShareIcon />
              </StyledIconButton>
            }

          </Side>

          <Title
            onClick={playing ? onPause : onPlay}
          >
            {<TitlePlayArrowIcon $playing={playing} />}
            {<TitlePauseIcon $playing={playing} />}
            {title}
          </Title>

          {!!onClose &&
            <Side>
              <Tooltip
                placement="top"
                title={i18n("Close")}
              >
                <StyledIconButton
                  tabIndex={-1}
                  onClick={pauseAndClose}
                  className="VideoPlayerCustomControls-CloseIcon"
                >
                  <CloseIcon />
                </StyledIconButton>
              </Tooltip>
            </Side>
          }

          {!onClose && !sharingTitle && !!sourceLink &&
            <Side>
              <NavLinkOrAWithDisable
                to={sourceLink}
              >
                <StyledIconButton
                  tabIndex={-1}
                >
                  <LaunchIcon />
                </StyledIconButton>
              </NavLinkOrAWithDisable>
            </Side>
          }

          {!!setDoAudio &&
            <VideoAudioSwitch
              isAudio={isAudio}
              setDoAudio={setDoAudio}
              hasAudio={hasAudio}
            />
          }

        </Main>

        <Bottom $show={!showSharing}>

          <ProgressBar {...seekProps}>
            <Progress
              style={{
                width: `${(currentSecs / duration) * 100}%`,
              }}
            />
            {!!hoverProgressProps &&
              <HoverProgress {...hoverProgressProps} />
            }
            {bookmarkMssToShow.map(bookmarkMs => {
              const myReactionTypeIndex = getMyReactionTypes().indexOf(`BOOKMARK:${bookmarkMs}`)
              const isMine = myReactionTypeIndex !== -1
              const numBookmarks = reactions[`BOOKMARK:${bookmarkMs}`]
              return (
                <Tooltip
                  key={bookmarkMs}
                  placement="top"
                  title={
                    isMine
                      ? (
                        numBookmarks > 1
                          ? (
                            numBookmarks > 2
                              ? i18n("You + {{num}} other(s)", { num: numBookmarks-1 })
                              : i18n("You + 1 other")
                          )
                          : i18n("Bookmarked by you")
                      )
                      : (
                        numBookmarks > 1
                          ? i18n("Bookmarked by {{num}} people", { num: numBookmarks })
                          : i18n("Bookmarked by 1 person", { num: numBookmarks })
                      )
                  }
                >
                  <BookmarkIconButton
                    tabIndex={-1}
                    $leftPercentage={((bookmarkMs/1000) / duration) * 100}
                    $isMine={isMine}
                    $selected={isMine && myReactionTypeIndex === myReactionTypeIndexInEdit}
                    data-seek-none
                    $seeking={(hoverProgressProps || {}).$seeking}
                    data-bookmark-ms={bookmarkMs}
                    onMouseDown={onClickBookmark}
                    onTouchStart={onClickBookmark}
                  >
                    <BookmarkIcon />
                  </BookmarkIconButton>
                </Tooltip>
              )
            })}
          </ProgressBar>

          <BigStyledIconButton
            ref={playPauseRef}
            onClick={playing ? onPause : onPlay}
            onKeyDown={onKeyDown}
          >
            {buffering && <StyledLoading size={18} />}
            {!buffering && playing && <PauseIcon />}
            {!buffering && !playing && <PlayArrowIcon />}
          </BigStyledIconButton>

          <Time>
            {getTimeStringFromSeconds(currentSecs)}
            {duration && ` / ${getTimeStringFromSeconds(duration)}`}
          </Time>

          <Spacer />

          {!!sourceIcon &&
            <NavLinkOrAWithDisable
              tabIndex={-1}
              to={sourceLink}
            >
              <StyledIconButton tabIndex={-1}>
                <SourceIcon src={sourceIcon} />
              </StyledIconButton>
            </NavLinkOrAWithDisable>
          }

          {!!setSpeed &&
            <>
              <StyledIconButton
                tabIndex={-1}
                onClick={openModal}
              >
                <IconLikeText>
                  {i18n("{{speed}}x", { speed: speed.toFixed(1).replace(/\.0$/, ``) })}
                </IconLikeText>
              </StyledIconButton>
              <StyledOptionsPopover
                anchorEl={anchorEl}
                onClose={closeModal}
                hideArrow
                horizontal="center"
              >
                <MenuList>

                  {[ .8, 1, 1.2, 1.5, 2, 2.5 ].map(speedOption => (
                    <StyledMenuItem
                      key={speedOption}
                      onClick={() => {
                        setSpeed(speedOption)
                        closeModal()
                      }}
                    >
                      {i18n("{{speed}}x", { speed: speedOption.toFixed(1) })}
                    </StyledMenuItem>
                  ))}

                </MenuList>
              </StyledOptionsPopover>
            </>
          }

          {!!togglePip && !isAudio &&
            <StyledIconButton
              tabIndex={-1}
              onClick={togglePip}
              disabled={showBookmarking}
            >
              <PictureInPictureIcon />
            </StyledIconButton>
          }

          {!!HAS_FULLSCREEN_OPTION && !isAudio &&
            <BigStyledIconButton
              tabIndex={-1}
              onClick={toggleFullscreen}
              disabled={showBookmarking}
            >
              <FullscreenIcon className="VideoPlayerCustomControls-fullscreenicon" />
              <FullscreenExitIcon className="VideoPlayerCustomControls-fullscreenexiticon" />
            </BigStyledIconButton>
          }

        </Bottom>

      </Container>
    </Tooltip>
  )

}

export default memo(VideoPlayerCustomControls)