import { memo, useCallback, useContext } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit'
import Tooltip from '@material-ui/core/Tooltip'
import { useMeasure } from 'react-use'

import { IS_EMBED } from '../../utils/constants'
import { StudyBibleItemInfoContext } from '../../context/StudyBibleItemInfo'

import PassageClose from './PassageClose'
import PassageNextPreviousButtons from './PassageNextPreviousButtons'
import PassageRefButton from './PassageRefButton'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;
  display: flex;
  background-color: rgb(255 255 255/.85);
  backdrop-filter: blur(3px);
  z-index: 2;
  overflow: hidden;
`

const ContainerForAllButClose = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  height: 48px;
  margin-right: 12px;
`

const Spacer = styled.div`
  flex: 1;
`

const BSBEntriesIconButton = styled(IconButton)`
  padding: 8px;
  margin: 4px 0;

  ${({ $showStudyBible, theme }) => !$showStudyBible ? `` : `
    color: ${theme.palette.primary.main};
  `}

  .MuiSvgIcon-root {
    transform: rotate(180deg);
  }
`

const PassageHeader = ({
  updatePassageBookmark,
  closeColumn,
  bookId,
  chapter,
  versionId,
  parallelVersionId,
  showStudyBible,
  setThisAsDragColumn,
  clearDragColumn,
  columnIdx,
  channelIdOrUrl,
}) => {

  const [ containerRef, { width } ] = useMeasure()
  const { recentlyClosedNote } = useContext(StudyBibleItemInfoContext) || {}
  const tellUserWhereToFindNotes = recentlyClosedNote && !showStudyBible

  const toggleShowStudyBible = useCallback(
    () => {
      updatePassageBookmark({
        showStudyBible: !showStudyBible,
      })
    },
    [ updatePassageBookmark, showStudyBible ],
  )

  return (
    <Container ref={containerRef}>

      <ContainerForAllButClose>

        <PassageRefButton
          bookId={bookId}
          chapter={chapter}
          versionId={versionId}
          parallelVersionId={parallelVersionId}
          updatePassageBookmark={updatePassageBookmark}
          setThisAsDragColumn={setThisAsDragColumn}
          clearDragColumn={clearDragColumn}
          abbreviated={width < 430}
        />

        <Spacer />

        {!channelIdOrUrl && !IS_EMBED &&
          <Tooltip
            key={tellUserWhereToFindNotes ? `tell-where-to-find` : `normal-tooltip`}
            arrow={tellUserWhereToFindNotes}
            open={tellUserWhereToFindNotes ? true : undefined}
            title={
              tellUserWhereToFindNotes
                ? i18n("Tap me to view study Bible entries, including your notes.")
                : (
                  showStudyBible
                    ? i18n("Hide Study Bible items")
                    : i18n("Show Study Bible items")
                )
            }
          >
            <BSBEntriesIconButton
              $showStudyBible={showStudyBible}
              onClick={toggleShowStudyBible}
              // onMouseDown={preventDefaultEvent}
              tabIndex={-1}
              className={`PassageHeader-BSBEntriesIconButton-${showStudyBible ? `active` : `disabled`}`}
            >
              <VerticalSplitIcon />
            </BSBEntriesIconButton>
          </Tooltip>
        }

        <PassageNextPreviousButtons
          bookId={bookId}
          chapter={chapter}
          versionId={versionId}
          updatePassageBookmark={updatePassageBookmark}
          columnIdx={columnIdx}
        />

      </ContainerForAllButClose>

      {!!closeColumn &&
        <PassageClose
          closeColumn={closeColumn}
        />
      }

    </Container>
  )
}

export default memo(PassageHeader)