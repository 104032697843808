import { useMemo, useContext } from 'react'

import { GET_UNHIDDEN_STUDY_BIBLE_ITEM_KEYS } from '../utils/constants'
import { dedup } from '../utils/misc'
import useAccountSetting from './useAccountSetting'
import { LoggedInUserContext } from '../context/LoggedInUser'

const unhiddenStudyBibleItemKeys = GET_UNHIDDEN_STUDY_BIBLE_ITEM_KEYS()

const emptyArray = []

const useChannelPriorities = () => {

  const { studyBibleFollows=emptyArray } = useContext(LoggedInUserContext) || {}
  const [ channelPriorities, updateChannelPriorities ] = useAccountSetting(`channel-priorities`, [])

  const normalizedChannelPriorities = useMemo(
    () => dedup(
      [

        ...channelPriorities.filter(({ type, studyBibleFollowId }) => (
          // get rid of any invalid
          (
            type === `CHANNEL`
            && studyBibleFollows.some(({ id }) => id === studyBibleFollowId)
          )
          || unhiddenStudyBibleItemKeys.includes(type)
        )),

        // make sure all follows and curated types are included
        ...unhiddenStudyBibleItemKeys.map(type => ({ type })),
        ...studyBibleFollows.map(({ id }) => ({ type: `CHANNEL`, studyBibleFollowId: id })),

      ],
      ({ type, studyBibleFollowId }) => `${type} ${studyBibleFollowId}`,
    ),
    [ studyBibleFollows, channelPriorities ],
  )

  return {
    studyBibleFollows,
    channelPriorities: normalizedChannelPriorities,
    updateChannelPriorities,
  }
}

export default useChannelPriorities