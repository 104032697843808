import { memo, useMemo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Divider from '@material-ui/core/Divider'
import { getLocFromRef, getStartAndEndVersesByChapter, getCorrespondingRefs, getRefFromLoc } from '@bibletags/bibletags-versification'

import { KJV_VERSION } from '../../utils/constants'
import { getOrigVersionInfo } from '../../utils/misc'
import useScrollSync from '../../hooks/useScrollSync'
import useVersionInfo from '../../hooks/useVersionInfo'
import useOnPassageLoadScrollCallback from '../../hooks/useOnPassageLoadScrollCallback'
import useAppSize from '../../hooks/useAppSize'
import useHighlights from '../../hooks/useHighlights'

import PassageContent from './PassageContent'
import PassageStudyBibleNotes from './PassageStudyBibleNotes'

const Page = styled.div`
  height: 100%;
  display: flex;
`

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const PassagePage = ({
  bookId,
  chapter,
  versionId,
  specialMarkup,
  parallelVersionId,
  showStudyBible,
  initialScrollYFraction,
  updateScrollYFraction,
  updatePassageBookmark,
  setSnackbarInfo,
  columnIdx,
  width,
  channelIdOrUrl,
  ...otherProps
}) => {

  const [ ref1, ref2 ] = useScrollSync({
    forceUpdateOnChange: !!parallelVersionId,
    updateScrollYFraction,
  })

  const { version } = useVersionInfo(versionId)
  const { miniHeaderSize } = useAppSize()

  const { notesAndSketches } = useHighlights({
    bookId,
    chapter,
    versionId,
    parallelVersionId,
    showStudyBible,
    noHighlights: !parallelVersionId || !showStudyBible,
  })

  const chapterInfo = useMemo(
    () => {
      const { startAndEndVersesByChapter } = getStartAndEndVersesByChapter({ versionInfo: version || KJV_VERSION, bookId })
      const startAndEndVerses = startAndEndVersesByChapter[chapter - 1]
      if(!startAndEndVerses) return null

      return {
        originalChapterLocs: [...new Set(
          startAndEndVerses
            .map((verse, idx) => (
              getCorrespondingRefs({
                baseVersion: {
                  ref: {
                    bookId,
                    chapter,
                    verse,
                  },
                  info: version || KJV_VERSION,
                },
                lookupVersionInfo: getOrigVersionInfo(),
                directionToTryIfSkipped: idx === 0 ? `next` : `previous`,
              })
            ))
            .flat()
            .map(ref => getLocFromRef(ref).slice(0,5))
        )],
        filterForVersion: ({ fromLoc, toLoc, type }) => {
          const [ fromRef, toRef ] = (
            [ fromLoc, type === `EVENT` ? null : toLoc ]  // for EVENT type, only place based on fromLoc
              .filter(Boolean)  // because toLoc might not be set
              .map((loc, idx) => (
                (getCorrespondingRefs({
                  baseVersion: {
                    ref: getRefFromLoc(loc),
                    info: getOrigVersionInfo(),
                  },
                  lookupVersionInfo: version || KJV_VERSION,
                  directionToTryIfSkipped: idx === 0 ? `next` : `previous`,
                }) || [])
                .flat()
                [idx === 0 ? `shift` : `pop`]()
              ))
          )
          return (
            fromRef
            && fromRef.bookId === bookId
            && fromRef.chapter <= chapter
            && ((toRef || {}).chapter || fromRef.chapter) >= chapter
          )
        },
      }

    },
    [ bookId, chapter, version ],
  )

  const { start, end } = ((specialMarkup || [])[0] || {}).positionInfo || {}
  const skipOnLoad = !(start || {}).loc || !(end || {}).loc
  const onLoad = useOnPassageLoadScrollCallback({
    ref: ref1,
    selector: (
      skipOnLoad
        ? `DUMMY_SELECTOR_TO_EFFECTIVELY_SKIP`
        : `[data-word-loc="${start.loc}:${start.wordNumberInVerse || 1}"],${
          end.wordNumberInVerse
            ? `[data-word-loc="${end.loc}:${end.wordNumberInVerse}"]`
            : `[data-word-loc^="${end.loc}:"]`
        }`
    ),
  })

  if(!chapterInfo) return null

  return (
    <Page {...otherProps} >

      <MainContent>

        <PassageContent
          bookId={bookId}
          chapter={chapter}
          versionId={versionId}
          specialMarkup={specialMarkup}
          showStudyBible={showStudyBible && !parallelVersionId}
          channelIdOrUrl={!parallelVersionId && channelIdOrUrl}
          ref={ref1}
          partOfParallel={!!parallelVersionId}
          initialScrollYFraction={initialScrollYFraction}
          columnIdx={columnIdx}  // purposely here but not on the parallel
          width={width}
          topPadding={miniHeaderSize ? `10px` : `48px`}
          updatePassageBookmark={updatePassageBookmark}
          chapterInfo={chapterInfo}
          onLoad={onLoad}
          setSnackbarInfo={setSnackbarInfo}
        />

        {!!parallelVersionId &&
          <>
            <Divider />
            <PassageContent
              bookId={bookId}
              chapter={chapter}
              versionId={parallelVersionId}
              ref={ref2}
              partOfParallel={true}
              initialScrollYFraction={initialScrollYFraction}
              width={width}
              updatePassageBookmark={updatePassageBookmark}
              chapterInfo={chapterInfo}
              setSnackbarInfo={setSnackbarInfo}
            />
          </>
        }

      </MainContent>

      {!!(showStudyBible || channelIdOrUrl) && !!parallelVersionId &&
        <PassageStudyBibleNotes
          chapterInfo={chapterInfo}
          versionId={versionId}
          width={width}
          channelIdOrUrl={channelIdOrUrl}
          notesAndSketches={notesAndSketches}
        />
      }

    </Page>
  )
}

export default memo(PassagePage)