import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import { getTextLanguageId, isRTLText } from '@bibletags/bibletags-ui-helper'
import IconButton from '@material-ui/core/IconButton'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import { cloneObj, equalTags, getPassageLineSpacing, preventDefaultEvent } from '../../../utils/misc'
import useVersionInfo from '../../../hooks/useVersionInfo'
import useEqualObjsMemo from '../../../hooks/useEqualObjsMemo'
import useVersesPieces from '../../../hooks/useVersesPieces'
import useAppSize from '../../../hooks/useAppSize'

import TextContent from '../../common/TextContent'
import Loading from '../../common/Loading'
import TaggerOriginalBlock from './TaggerOriginalBlock'
import TaggerSubmitButton from './TaggerSubmitButton'

const Container = styled.div`
  height: min(500px, calc(100vh - 150px));
  width: 400px;
  max-width: 100%;
  text-align: center;
  padding: 100px 20px;
  font-size: 18px;
  font-weight: 300;
  position: absolute;
`

const Spacer = styled.div`
  min-height: calc(50vh);
  width: 30px;
`

const Status = styled.div`
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  white-space: nowrap;
  gap: 5px;
  margin: 20px 0;
`

const StatusValue = styled.div`
  font-weight: 500;
`

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 20px;
`

const StyledDoneOutlineIcon = styled(DoneOutlineIcon)`
  color: ${({ theme }) => theme.palette.sixthly.main};
  font-size: 20px;
`

const StyledBrightnessAutoIcon = styled(BrightnessAutoIcon)`
  color: ${({ theme }) => theme.palette.tertiary.light};
  font-size: 20px;
`


const TranslationBlock = styled.div`
  font-size: 18px;
  line-height: ${({ $lineSpacing }) => $lineSpacing};
  direction: ${({ $isRTL }) => $isRTL ? "rtl" : "ltr"};
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  cursor: default;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  .text-content-word[data-word-loc] {
    padding: 5px 6px;
    margin: 0 -3px;
    opacity: .75;
  }

  ${({ theme, $selectedOriginalWordIdAndPartNumbers }) => $selectedOriginalWordIdAndPartNumbers.length === 0 ? `` : `
    @media (hover: hover) {
      .text-content-word[data-word-loc]:hover {
        cursor: pointer;
        color: ${theme.palette.grey[600]};
      }
    }
  `}

  ${({ $tags, $loc, $selectedOriginalWordIdAndPartNumbers }) => (
    $tags.map(({ o, t }) => (
      o.length > 0
      && (
        t.map(wordNumberInVerse => `
          .text-content-word[data-word-loc="${$loc}:${wordNumberInVerse}"] {
            text-decoration: line-through;
            opacity: ${o.some(wordIdAndPartNumber => $selectedOriginalWordIdAndPartNumbers.includes(wordIdAndPartNumber)) ? 1 : .25};
          }
        `)
      ))
    )
  )}
`

const LeftArrowIconButton = styled(IconButton)`
  position: absolute;
  top: 100px;
  left: calc(50% - 320px);
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: white;

  .MuiSvgIcon-root {
    font-size: 40px;
  }

  @media (max-width: 700px) {
    padding: 7px;
    left: calc(50% - 290px);

    .MuiSvgIcon-root {
      font-size: 30px;
    }
  }
`

const RightArrowIconButton = styled(LeftArrowIconButton)`
  right: calc(50% - 320px);
  left: auto;

  @media (max-width: 700px) {
    right: calc(50% - 290px);
    left: auto;
  }
`

const SubmissionStatus = styled.div`
  font-weight: 300;
  margin: -18px 0 18px;
  color: ${({ theme }) => theme.palette.grey[600]};
  font-size: 13.5px;
  text-align: center;
`

const SubmissionStatusMatch = styled.span`
  display: inline-block;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.fourthly.dark};
`

const SubmissionStatusNoMatch = styled(SubmissionStatusMatch)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

const TaggerContent = ({
  versionId,
  loc,
  originalRefs,
  originalPieces,
  infoByWordIdAndPartNumber,
  tags,
  setTags,
  loading,
  loadingMyTagSetSubmissions,
  myTagSet,
  currentlyAcceptedTags,
  selectedOriginalWordIdAndPartNumbers,
  setSelectedOriginalWordIdAndPartNumbers,
  tagSetStatus,
  onClickPrevious,
  onClickNext,
  goSubmitTagSet,
  goSetPopperInfo,
  goToNextToConfirm,
  markConfirmed,
}) => {

  const { version } = useVersionInfo(versionId)
  const { miniHeaderSize } = useAppSize()

  const translationRef = useEqualObjsMemo(getRefFromLoc(loc))
  const { bookId } = translationRef

  const { languageId: preadjustedLanguageId } = version || {}
  const languageId = getTextLanguageId({ languageId: preadjustedLanguageId, bookId })
  const isRTL = isRTLText({ languageId, bookId })
  const lineSpacing = getPassageLineSpacing({
    lineSpacingSizes: { base: 2.6, grc: 2.5, heb: 2.7 },
    languageId,
  })

  const hasMyTagSet = !!myTagSet
  const myTagSetMatchesCurrentlyAcceptedTags = !!myTagSet && equalTags(currentlyAcceptedTags, myTagSet.tags)

  const [ translationPieces, translationLoading ] = useVersesPieces({
    locs: [ loc ],
    versionId,
  })

  const onClickTranslation = useCallback(
    event => {

      preventDefaultEvent(event)

      const el = event.target.closest(`[data-word-loc]`)
      const wordNumberInVerse = el && parseInt((el.getAttribute('data-word-loc') || ``).split(`:`)[1], 10)
      let newTags = cloneObj(tags)

      if(selectedOriginalWordIdAndPartNumbers.length === 0) return
      if(!wordNumberInVerse) return

      // see if this translation word is already connected to the selected orig word group
      const isCurrentlyTaggedToSelectedOriginalWords = newTags.some(({ o, t }) => (
        t.includes(wordNumberInVerse)
        && o.length === selectedOriginalWordIdAndPartNumbers.length
        && o.every(wordIdAndPartNumber => selectedOriginalWordIdAndPartNumbers.includes(wordIdAndPartNumber))
      ))

      // disconnect this translation word from all word groups
      newTags = newTags.filter(tag => {
        tag.t = tag.t.filter(wordNumberInVerse2 => wordNumberInVerse2 !== wordNumberInVerse)
        return tag.t.length > 0
      })

      // connect this translation word from all word groups, if it wasn't previously connected
      if(!isCurrentlyTaggedToSelectedOriginalWords) {
        const existingTag = newTags.find(({ o }) => (
          o.length === selectedOriginalWordIdAndPartNumbers.length
          && o.every(wordIdAndPartNumber => selectedOriginalWordIdAndPartNumbers.includes(wordIdAndPartNumber))
        ))
        if(existingTag) {
          existingTag.t.push(wordNumberInVerse)
        } else {
          newTags.push({
            o: selectedOriginalWordIdAndPartNumbers,
            t: [ wordNumberInVerse ],
          })
        }
      }

      setTags(newTags)

    },
    [ selectedOriginalWordIdAndPartNumbers, tags, setTags ],
  )

  if(loading || translationLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    )
  }

  if(originalRefs.length === 0) {
    return (
      <Container>
        {i18n("This verse does not exist in the original.")}
      </Container>
    )
  }

  return (
    <>

      <Status>
        {i18nReact("Tagging status: {{status}}", {
          status: (
            <StatusValue>
              {{
                confirmed: i18n("Complete"),
                unconfirmed: i18n("Provisional"),
                automatch: i18n("Auto-match"),
                none: i18n("None"),
              }[tagSetStatus]}
            </StatusValue>
          ),
        })}
        {tagSetStatus === `confirmed` && <StyledCheckCircleIcon />}
        {tagSetStatus === `unconfirmed` && <StyledDoneOutlineIcon />}
        {tagSetStatus === `automatch` && <StyledBrightnessAutoIcon />}
      </Status>

      {hasMyTagSet &&
        <SubmissionStatus>
          {i18nReact("Your submission {{status}} the accepted tags.", {
            status: (
              myTagSetMatchesCurrentlyAcceptedTags
                ? <SubmissionStatusMatch>{i18n("matches")}</SubmissionStatusMatch>
                : <SubmissionStatusNoMatch>{i18n("do not match")}</SubmissionStatusNoMatch>
            ),
          })}
        </SubmissionStatus>
      }

      <TaggerOriginalBlock
        originalPieces={originalPieces}
        selectedOriginalWordIdAndPartNumbers={selectedOriginalWordIdAndPartNumbers}
        setSelectedOriginalWordIdAndPartNumbers={setSelectedOriginalWordIdAndPartNumbers}
        contextRef={originalRefs[0]}
        infoByWordIdAndPartNumber={infoByWordIdAndPartNumber}
        tags={tags}
        setTags={setTags}
        translationIsRTL={isRTL}
        goSetPopperInfo={goSetPopperInfo}
      />

      <TranslationBlock
        $isRTL={isRTL}
        $lineSpacing={lineSpacing}
        $loc={loc}
        $tags={tags}
        $selectedOriginalWordIdAndPartNumbers={selectedOriginalWordIdAndPartNumbers}
        onClick={onClickTranslation}
      >
        <TextContent
          pieces={translationPieces}
          versionId={versionId}
          bookId={translationRef.bookId}
          startChapter={translationRef.chapter}
          startVerse={translationRef.verse}
          showCfs={false}
          showNotes={false}
          goSetPopperInfo={goSetPopperInfo}
        />
      </TranslationBlock>

      <TaggerSubmitButton
        hasMyTagSet={hasMyTagSet}
        tags={tags}
        loadingMyTagSetSubmissions={loadingMyTagSetSubmissions}
        myTagSet={myTagSet}
        goSubmitTagSet={goSubmitTagSet}
        translationPieces={translationPieces}
        onClickNext={onClickNext}
        goToNextToConfirm={goToNextToConfirm}
        markConfirmed={markConfirmed}      
        tagSetStatus={tagSetStatus}
      />

      {!miniHeaderSize &&
        <>

          <LeftArrowIconButton
            onClick={onClickPrevious}
            disabled={!onClickPrevious}
          >
            <ArrowBackIcon />
          </LeftArrowIconButton>

          <RightArrowIconButton
            onClick={onClickNext}
            disabled={!onClickNext}
          >
            <ArrowForwardIcon />
          </RightArrowIconButton>

        </>
      }

      <Spacer />

    </>
  )
}

export default memo(TaggerContent)