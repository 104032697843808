import { memo } from 'react'
import styled from 'styled-components'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import ImageIcon from '@material-ui/icons/Image'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import Tooltip from '@material-ui/core/Tooltip'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'

import useChannelInfo from '../../../hooks/useChannelInfo'

import MyChannelsListOptionsMenu from "./MyChannelsListOptionsMenu"

const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  margin: -1px -1px 0 -1px;
  padding: 15px;
  background-color: white;

  ${({ $hide }) => !$hide ? `` : `
    & > div:not(.MyChannelsListLine-Buttons) {
      opacity: .35;
    }
  `}
`

const ChannelSquare = styled.div`
  width: 43px;
  height: 43px;
  margin-right: 7px;
  border-radius: 5px;
  background-color: ${({ $color }) => $color};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: rgb(255 255 255/.9);
  font-size: 25px;

  &::after {
    content: "";
    position: absolute;
    inset: 0px;
    background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0.4) 41%, rgba(255, 255, 255, 0.3) 60%, rgba(0, 0, 0, 0.1) 100%);
    transition: all 0.3s ease-in-out 0s;
    background-size: 300%;
  }
`

const ChannelImage = styled.img`
  width: 43px;
  height: 43px;
  margin-right: 7px;
  object-fit: cover;
  border-radius: 5px;
`

const HandleContainer = styled.div`
  height: 36px;
  width: 36px;
`

const StyledDragHandleIcon = styled(DragHandleIcon)`
  padding: 6px;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.palette.grey[500]};

  &:hover {
    color: ${({ theme }) => theme.palette.grey[900]};
    cursor: move;
  }
`

const ChannelInfo = styled.div`
  flex: 1;
  padding: 0 5px;
  overflow: hidden;

  ${({ $hide, theme }) => !$hide ? `` : `
    color: ${theme.palette.grey[500]};
  `}
`

const ChannelName = styled.div`
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
`

const OtherChannelInfo = styled.div`
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const NumSermons = styled.div`
`

const ShortName = styled.span`
  font-weight: bold;
`

const Preview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 7px;
`

const IOSPreviewImage = styled.div`
  width: 43px;
  height: 43px;
  background-image: url(${({ $src }) => $src});
  background-size: contain;
  user-select: none;
`

const MyChannelsListLine = ({
  type,
  id,
  channelUrl,
  channelId,
  hide,
  toggleHide,
  dragHandleProps,
}) => {

  let {
    aboutUs,  // for broadcasters
    bio,  // for speakers
    // broadcasterID,
    // speakerID,
    displayName,
    location,
    minister,
    numberOfSermons,  // for broadcasters
    sermonCount,  // for speakers
    homePageURL,
    phone,
    name,
    shortName,
    // preferredVersionId,
    description,
    updatedAt,
    stats,
    label,
    color,
    notDarkModeExempt,
    channelImg,
    loading,
  } = useChannelInfo({ channelId, channelUrl, type })

  const nameToUse = (
    displayName
    || label
    || (
      shortName
      && (
        <>
          {i18nReact("The {{short_name}} Study Bible", {
            short_name: (
              <ShortName>
                {shortName}
              </ShortName>
            ),
          })}
        </>
      )
    )
    || (loading && i18n("Loading..."))
    || `??`
  )

  const info = (
    channelUrl
    || name
    || ([ `INSIGHT`, `INTRODUCTION` ].includes(type) && i18n("From the Biblearc Team"))
    || ([ `MAP`, `GENEALOGY`, `TIMELINE`, `COURSE` ].includes(type) && i18n("From Biblearc"))
    || ([ `QUOTE`, `SERMON`, `LECTURE`, `PODCAST`, `IMAGE`, `CREED` ].includes(type) && i18n("Biblearc Team curated content"))
  )

  const numSermons = (
    numberOfSermons
    || sermonCount
    || (
      stats
      && Object.values(stats.channelUrls).reduce((acc, { numSermons }) => acc + numSermons, 0)
    )
  )

  const url = (
    homePageURL
    || (
      channelId
      && `${window.location.origin}/church/${channelId}`
    )
  )

  return (
    <Container $hide={hide}>

      {!!channelId &&
        <Preview>
          <IOSPreviewImage
            $src={`${process.env.REACT_APP_ASSETS_URI}${channelId}-favicon_192-${updatedAt}.png`}
            className="dark-mode-exempt"
          />
        </Preview>
      }

      {color &&
        <ChannelSquare $color={color}>
          {[ `INSIGHT` ].includes(type) && <NewReleasesIcon />}
          {[ `QUOTE`, `CREED` ].includes(type) && <FormatQuoteIcon />}
          {[ `SERMON`, `LECTURE`, `PODCAST`, `COURSE` ].includes(type) && <PlayCircleFilledIcon />}
          {[ `IMAGE` ].includes(type) && <ImageIcon />}
        </ChannelSquare>
      }

      {!color && !channelId &&
        <ChannelImage
          src={channelImg}
          className={notDarkModeExempt ? `` : `dark-mode-exempt`}
        />
      }

      <ChannelInfo>

        <ChannelName>
          {nameToUse}
        </ChannelName>

        <OtherChannelInfo>
          {info}
        </OtherChannelInfo>

        <NumSermons>
        </NumSermons>

      </ChannelInfo>

      <Buttons className="MyChannelsListLine-Buttons">

        <Tooltip
          title={i18n("Drag to reorder")}
        >
          <HandleContainer {...dragHandleProps}>
            <StyledDragHandleIcon />
          </HandleContainer>
        </Tooltip>

        <MyChannelsListOptionsMenu
          channelId={id}
          hide={hide}
          toggleHide={toggleHide}
          aboutUs={aboutUs || bio || description}
          displayName={nameToUse}
          imageURL={channelImg}
          location={location}
          minister={minister}
          numberOfSermons={numSermons}
          homePageURL={url}
          phone={phone}
        />

      </Buttons>

    </Container>
  )
}

export default memo(MyChannelsListLine)