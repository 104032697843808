import { getRefFromLoc } from "@bibletags/bibletags-versification"

import {
  IS_EMBED,
  MAX_ORIG_WORDS_FOR_SEARCH_ALL_SUGGESTION,
} from "./constants"
import { getEmbedFullScreen, getEmbedSettings } from "../graphql/links/embedLink"

const MAX_TEXT_RANGE_UPDATES_AT_ONCE = 10
let recentTextRangeUpdateTimes = []

const selectWholeWords = ({
  textSelectionInfo,
  wordSelector=".text-content-word[data-word-loc]",
  selection,
  versionId,
}) => {

  const {
    textRange,
    startNode,
    startNodeOffset,
    endNode,
    endNodeOffset,
  } = textSelectionInfo

  const updateTextRange = () => {

    const now = Date.now()

    if(
      recentTextRangeUpdateTimes.length >= MAX_TEXT_RANGE_UPDATES_AT_ONCE
      && now - recentTextRangeUpdateTimes[0] < 100
    ) {
      // MAX_TEXT_RANGE_UPDATES_AT_ONCE updates in less than .1 seconds; this is a likely infinite loop and so don't do it!
      console.error(`Selection adjustment prevented due to likely infinite loop.`)
      return
    }

    selection.empty()
    selection.addRange(textRange)

    recentTextRangeUpdateTimes = [
      ...recentTextRangeUpdateTimes.slice(0, MAX_TEXT_RANGE_UPDATES_AT_ONCE - 1),
      now,
    ]

  }

  let firstWordEl, lastWordEl
  const allWordEls = []
  const atEndOfEndNode = endNodeOffset === (endNode.length === undefined ? endNode.childNodes.length : endNode.length)

  let innerStartEl = (
    startNode.nodeType === Node.ELEMENT_NODE
      ? startNode
      : (
        startNode.parentElement.closest(wordSelector)
          ? startNode.parentElement
          : null
      )
  )
  while(
    startNodeOffset === 0
    && innerStartEl
    && (innerStartEl.firstChild || {}).nodeType === Node.ELEMENT_NODE
  ) {
    innerStartEl = innerStartEl.firstChild
  }

  let innerEndEl = (
    endNode.nodeType === Node.ELEMENT_NODE
      ? endNode
      : (
        endNode.parentElement.closest(wordSelector)
          ? endNode.parentElement
          : null
      )
  )
  while(
    atEndOfEndNode
    && innerEndEl
    && (innerEndEl.lastChild || {}).nodeType === Node.ELEMENT_NODE
  ) {
    innerEndEl = innerEndEl.lastChild
  }

  let nodeToStartFrom = innerStartEl && (innerStartEl.closest(wordSelector) || innerStartEl)
  let startNodeOrAncestor = startNode
  while(startNodeOrAncestor && !nodeToStartFrom) {
    if(startNodeOrAncestor.nextElementSibling) {
      nodeToStartFrom = startNodeOrAncestor.nextElementSibling
    }
    startNodeOrAncestor = startNodeOrAncestor.parentElement
  }
  if(nodeToStartFrom) {
    const walker = document.createTreeWalker(textRange.commonAncestorContainer, NodeFilter.SHOW_ALL)
    walker.currentNode = nodeToStartFrom
    while(true) {
      if(walker.currentNode.nodeType === Node.ELEMENT_NODE) {
        const wordEl = walker.currentNode.closest(wordSelector)
        firstWordEl = firstWordEl || wordEl
        lastWordEl = wordEl || lastWordEl
        if(wordEl && !allWordEls.includes(wordEl)) allWordEls.push(wordEl)
      }
      if(walker.currentNode === endNode) break
      if(!walker.nextNode()) break // returns null if there are no more
    }
  }

  const selectionWithinOneWord = allWordEls.length < 2
  let isAtStartOfFirstWordEl=false, isAtEndOfLastWordEl=false, selectionStart={}, selectionEnd={}

  if(firstWordEl) {

    if(
      innerStartEl
      && innerStartEl.closest(wordSelector) === firstWordEl
      && !selectionWithinOneWord
      && !(  // i.e. prevent infinite loop
        textRange.startContainer === firstWordEl
        && textRange.startOffset === 0
      )
    ) {
      // move selection to start of the first word
      textRange.setStart(firstWordEl, 0)
      updateTextRange()
    }

    let [ loc, wordNumberInVerse ] = firstWordEl.getAttribute(`data-word-loc`).split(':')
    wordNumberInVerse = parseInt(wordNumberInVerse, 10)
    selectionStart = {
      loc,
      wordNumberInVerse,
    }

    if(startNodeOffset === 0) {
      let el = firstWordEl
      while(el && el.nodeType === Node.ELEMENT_NODE) {
        if(el === innerStartEl) {
          isAtStartOfFirstWordEl = true
          break
        }
        el = el.firstChild
      }
    }

  }

  if(lastWordEl) {

    if(
      innerEndEl
      && innerEndEl.closest(wordSelector) === lastWordEl
      && !selectionWithinOneWord
      && !(  // i.e. prevent infinite loop
        textRange.endContainer === lastWordEl
        && textRange.endOffset === lastWordEl.childNodes.length
      )
    ) {
      // move selection to end of the last word
      textRange.setEnd(lastWordEl, lastWordEl.childNodes.length)
      updateTextRange()
    }

    let [ loc, wordNumberInVerse ] = lastWordEl.getAttribute(`data-word-loc`).split(':')
    wordNumberInVerse = parseInt(wordNumberInVerse, 10)
    selectionEnd = {
      loc,
      wordNumberInVerse,
    }

    if(atEndOfEndNode) {
      let el = lastWordEl
      while(el && el.nodeType === Node.ELEMENT_NODE) {
        if(el === innerEndEl) {
          isAtEndOfLastWordEl = true
          break
        }
        el = el.lastChild
      }
    }

  }

  let verseOrHigherCommonContainer = (
    textRange.commonAncestorContainer.nodeType === Node.ELEMENT_NODE
      ? textRange.commonAncestorContainer
      : textRange.commonAncestorContainer.parentElement
  )
  verseOrHigherCommonContainer = verseOrHigherCommonContainer.closest(`.TextContent-verse`) || verseOrHigherCommonContainer

  const completeVersesSelection = !!(
    selectionStart.wordNumberInVerse === 1
    && selectionEnd.loc
    && firstWordEl !== lastWordEl
    && !verseOrHigherCommonContainer.querySelector(`${wordSelector}[data-word-loc="${selectionEnd.loc}:${selectionEnd.wordNumberInVerse + 1}"]`)
  )

  const partialWordSelection = selectionWithinOneWord && (!isAtStartOfFirstWordEl || !isAtEndOfLastWordEl)

  const copyText = selection.toString().replace(/(?:^|\u00A0? )●(?: |$)/g, ' ').replace(/  +/g, ' ').trim()

  let searchStr, words
  if(firstWordEl && firstWordEl.getAttribute(`data-word-strong`)) {
    if(!partialWordSelection) {
      words = allWordEls.map(el => ({
        strong: el.getAttribute(`data-word-strong`),
        morph: el.getAttribute(`data-word-morph`),
        form: el.innerText,
        lemma: el.getAttribute(`data-word-lemma`),
      }))
    }
  } else {
    searchStr = selection.toString().replace(/\s/g, ' ').replace(/[^\p{L}\p{N} ]/gu, '').replace(/  +/g, ' ').trim()
    if(
      selectionWithinOneWord
      && isAtStartOfFirstWordEl
      && !isAtEndOfLastWordEl
    ) {
      searchStr += `*`
    }
  }

  if(!versionId && firstWordEl) {
    const elWithVersionId = firstWordEl.closest(`[data-versionid]`)
    if(elWithVersionId) {
      versionId = elWithVersionId.getAttribute(`data-versionid`)
    }
  }

  const showSearchSuggestions = (
    (
      (
        words
        && words.length <= (
          versionId === `lxx`
            ? MAX_ORIG_WORDS_FOR_SEARCH_ALL_SUGGESTION
            : 20
        )
      )
      || (
        searchStr
        && searchStr.length < 50
      )
    )
    && !(
      IS_EMBED
      && (
        !getEmbedFullScreen()
        || getEmbedSettings().disableOriginalLanguageHelps
      )
    )
  )

  const bookId = getRefFromLoc(selectionStart.loc || ``).bookId || undefined

  return {
    isAtStartOfFirstWordEl,
    isAtEndOfLastWordEl,
    selectionStart,
    selectionEnd,
    selectionWithinOneWord,
    completeVersesSelection,
    firstWordEl,
    lastWordEl,
    allWordEls,
    partialWordSelection,
    copyText,
    searchStr,
    words,
    showSearchSuggestions,
    bookId,
  }
}

export default selectWholeWords