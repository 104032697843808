import React, { memo, useContext } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
// import ShareIcon from '@material-ui/icons/Share'
// import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'

import usePassageRef from '../../hooks/usePassageRef'
// import useShareOrCopy from '../../hooks/useShareOrCopy'
import useInstanceValuesCallback from '../../hooks/useInstanceValuesCallback'
import useGoUpdateHighlight from '../../hooks/useGoUpdateHighlight'
import useThrottleCallback from '../../hooks/useThrottleCallback'
import { LoggedInUserContext } from '../../context/LoggedInUser'
import { cutOffString } from '../../utils/misc'

import ManilaPaper from '../common/ManilaPaper'
import Editor from '../editor/Editor'
import RelativeTime from '../common/RelativeTime'

const Container = styled.div`
  width: min(430px, calc(100vw - 30px));
  border-radius: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 5px 0;
`

const HeaderInfo = styled.div`
  flex: 1;
  padding: 10px 15px;
`

const HeaderInfoLine = styled.div`
`

const HeadingLine = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 7px;
  font-size: 12px;
  margin-top: 3px;
`

const HeadingAndTags = styled.div`
`

const PassageRefContainer = styled.span`
  display: inline-block;
  font-size: 15px;
`

const Heading = styled.span`
  font-weight: 300;
  font-style: italic;
  margin-right: 2px;
`

const ManilaPaperContainer = styled.div`
  padding: 0 15px;
`

const StyledIconButton = styled(IconButton)`
  padding: 8px;
  margin: 1px 6px 4px 0;

  .MuiSvgIcon-root {
    height: 20px;
    width: 20px;
  }
`

const LastUpdated = styled.div`
  font-size: 10px;
  font-weight: 300;
  text-align: right;
  padding: 5px 15px 10px;
`

const StyledEditor = styled(Editor)`
  max-height: max(calc(100dvh - 530px), 200px);  /* making room for the virtual keyboard */
  min-height: 150px;
  display: flex;
  flex-direction: column;

  .ToolbarPlugin-Container {
    position: relative;
    top: 8px;
    left: -4px;
    width: calc(100% + 8px);
    z-index: 1;
  }

  [data-lexical-editor] {
    overflow-y: auto;
    min-height: 0;
    margin: -44px -15px 0;
    padding: 49px 15px 2px;
  }

`

const StudyBibleNote = ({
  highlight,
  onClose,
  setSnackbarInfo,
  // fromThumbnail,
}) => {

  const user = useContext(LoggedInUserContext)
  const [ goUpdateHighlight, goDeleteHighlight ] = useGoUpdateHighlight({ highlight })

  const {
    __typename,
    id,
    note,
    shared,
    userId,
    // user,  // TODO
    info,
    createdAt,
    savedAt,
    versionId,
    ...fromAndToLocInfo
  } = highlight || {}

  const { bibleText } = info || {}
  const quotedBibleText = i18n("“{{quote}}”", { quote: cutOffString(bibleText, 150) })

  const isMine = userId === user.id

  const passageStr = usePassageRef({
    ...fromAndToLocInfo,
  })

  // const text = ``  // TODO
  // const userName = ""  // TODO

  // const { copyOrShare, justCopiedOrShared, copyOptionsComponent } = useShareOrCopy({
  //   shareData: {
  //     title: i18n("{{user_name}}’s notes on {{passage_ref}}", {
  //       user_name: userName,
  //       passage_ref: passageStr,
  //     }),
  //     text,
  //     url: `${document.location.origin}/study-bible-note/${id}`,
  //   },
  //   giveShareOnXOption: true,
  // })

  const goDeleteAndClose = useInstanceValuesCallback(
    () => {

      onClose()
      goDeleteHighlight()

      const closeSnackbar = () => setSnackbarInfo({ ...snackbarInfo, open: false })
      const snackbarInfo = {
        open: true,
        message: i18n("Note deleted"),
        onClose: closeSnackbar,
        onClick: () => {
          goUpdateHighlight({ deletedAt: null })
          closeSnackbar()
        },
      }
      setSnackbarInfo(snackbarInfo)

    }
  )

  const goSave = useInstanceValuesCallback(note => goUpdateHighlight({ note }))
  const [ goThrottledSave, flushSave ] = useThrottleCallback(goSave)

  return (
    <Container
      className="StudyBibleNote-Container"
    >

      <Header>

        <HeaderInfo>

          <HeaderInfoLine>

            <PassageRefContainer>
              {passageStr}
            </PassageRefContainer>

          </HeaderInfoLine>

          <HeadingLine>

            <HeadingAndTags>

              <Heading>
                {quotedBibleText}
              </Heading>

            </HeadingAndTags>

          </HeadingLine>

        </HeaderInfo>

        <Tooltip title={i18n("Delete this note")}>
          <StyledIconButton
            onClick={goDeleteAndClose}
          >
            <DeleteIcon />
          </StyledIconButton>
        </Tooltip>

        {/* <StyledIconButton
          onClick={copyOrShare}
          color={justCopiedOrShared ? `primary` : `default`}
        >
          {justCopiedOrShared ? <CheckIcon /> : <ShareIcon />}
        </StyledIconButton>

        {copyOptionsComponent} */}

      </Header>

      <ManilaPaperContainer>
        <ManilaPaper>

          <StyledEditor
            editable={isMine}
            initialContent={note}
            onUpdate={goThrottledSave}
            onBlur={flushSave}
          />

        </ManilaPaper>
      </ManilaPaperContainer>

      <LastUpdated>
        {i18nReact("Last updated: {{date}}", {
          date: <RelativeTime date={savedAt} />,
        })}
      </LastUpdated>

    </Container>
  )
}

export default memo(StudyBibleNote)